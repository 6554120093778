.container[data-v-2d0159fb] {
  display: flex;
  width: 100%;
  height: 100vh;
}
.container .content[data-v-2d0159fb] {
  position: relative;
  display: flex;
  width: calc(100% - 2.34375rem);
}
.container .areaBoxs[data-v-2d0159fb] {
  width: calc(100% - 0px);
  height: calc(100% - 1.04167rem);
  padding: 0.02604rem 0.05208rem 0.02604rem 0.13021rem;
  margin: 0.20833rem 0.125rem;
  overflow-y: scroll;
  z-index: 1;
}
.container .areaBoxs .noMore[data-v-2d0159fb] {
  flex: 0 0 100%;
  height: 0.10417rem;
  color: #ccc;
  text-align: center;
}
.container .areaBoxs .item[data-v-2d0159fb] {
  flex: 0 0 calc(50% - 0.20833rem);
  width: calc(50% - 0.20833rem);
  display: flex;
  float: left;
  justify-content: space-between;
  align-items: center;
  height: 0.78125rem;
  padding: 0.13021rem 0.15625rem;
  margin: 0 0.20833rem 0.15625rem 0;
  background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png) no-repeat center;
  background-size: cover;
  border-radius: 0.07292rem;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}
.container .areaBoxs .item.cur[data-v-2d0159fb] {
  background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
}
.container .areaBoxs .item .txt[data-v-2d0159fb] {
  font-size: 0.14583rem;
  font-weight: 800;
  color: #ffffff;
}
.container .areaBoxs .item .price[data-v-2d0159fb] {
  display: flex;
  align-items: baseline;
  font-size: 0.10417rem;
  font-weight: bold;
  color: #0436b5;
}
.container .areaBoxs .item .price .num[data-v-2d0159fb] {
  font-size: 0.20833rem;
}
.container .areaBoxs[data-v-2d0159fb]::-webkit-scrollbar {
  width: 0.04167rem;
  height: 0.04167rem;
}
.container .areaBoxs[data-v-2d0159fb]::-webkit-scrollbar-track {
  background-color: #4B4B4B;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .areaBoxs[data-v-2d0159fb]::-webkit-scrollbar-thumb {
  background-color: #D5A223;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .footer[data-v-2d0159fb] {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0.625rem;
  background: linear-gradient(180deg, #333C58 0%, #1B2731 100%);
  padding: 0 0.23438rem;
}
.container .footer .price[data-v-2d0159fb] {
  display: flex;
  align-items: center;
  font-size: 0.13542rem;
  font-weight: 700;
  color: #fff;
}
.container .footer .price .num[data-v-2d0159fb] {
  display: flex;
  font-size: 0.20833rem;
  color: #D6A324;
}
.container .footer .price .num i[data-v-2d0159fb] {
  font-size: 0.10417rem;
  font-style: initial;
  margin-top: 0.05729rem;
}
.container .footer .btns[data-v-2d0159fb] {
  display: flex;
}
.container .footer .btns.disable .btn[data-v-2d0159fb] {
  background: #958787 !important;
  cursor: default !important;
}
.container .footer .btns .btn[data-v-2d0159fb] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.15625rem;
  height: 0.3125rem;
  line-height: 0.3125rem;
  background: linear-gradient(109deg, #00CBFF 0%, #07BDE6 60%, #0BB3D6 100%);
  border-radius: 0.15625rem;
  color: #fff;
  font-size: 0.13542rem;
  font-weight: 700;
  cursor: pointer;
}
.container .footer .btns .btn.btn-buy[data-v-2d0159fb] {
  margin-left: 0.15625rem;
  background: linear-gradient(109deg, #D5A223 0%, #FFD263 100%);
}
.container .purchased[data-v-2d0159fb] {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 2.34375rem;
  height: 100vh;
  background: #333C58;
}
.container .purchased .title[data-v-2d0159fb] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.15625rem 0.13021rem 0.13021rem 0.15625rem;
  font-size: 0.15625rem;
  color: #fff;
  font-weight: 700;
  background: #333C58;
}
.container .purchased .list[data-v-2d0159fb] {
  width: 100%;
  height: calc(100vh - 0.52083rem);
  padding: 0 0.15625rem 0.15625rem;
  display: flex;
  flex-direction: column;
  margin: 0.52083rem 0 0.15625rem;
  overflow-y: scroll;
}
.container .purchased .list[data-v-2d0159fb]::-webkit-scrollbar {
  width: 0.04167rem;
  height: 0.04167rem;
}
.container .purchased .list[data-v-2d0159fb]::-webkit-scrollbar-track {
  background-color: rgba(192, 169, 22, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .purchased .list[data-v-2d0159fb]::-webkit-scrollbar-thumb {
  background-color: #939399;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .purchased .list .noMore[data-v-2d0159fb] {
  height: 0.10417rem !important;
  color: #ccc;
  text-align: center;
}
.container .purchased .list .item[data-v-2d0159fb] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 0.625rem;
  padding: 0.20833rem 0.14583rem;
  margin-bottom: 0.10417rem;
  color: #fff;
  background: url(https://ipqncdn1.artuns.com/yj/1683876909211_purchased_bg.png) no-repeat center;
  background-size: cover;
  border-radius: 0.07292rem;
}
.container .purchased .list .item .name[data-v-2d0159fb] {
  font-size: 0.14583rem;
  font-weight: 700;
}
.container .purchased .list .item .txt[data-v-2d0159fb] {
  font-size: 0.10417rem;
}
.container[data-v-2d0159fb] .el-dialog__wrapper .el-dialog {
  width: 2.60417rem !important;
  border-radius: 0.05208rem;
}
.container[data-v-2d0159fb] .el-dialog__wrapper .el-dialog .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.container[data-v-2d0159fb] .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 0.15625rem;
}
.container[data-v-2d0159fb] .el-dialog__wrapper .code {
  width: 2.60417rem;
  height: 2.60417rem;
}
.container[data-v-2d0159fb] .el-dialog__wrapper .tips {
  text-align: center;
}
