.addresinp_view[data-v-49bb0f52] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.20833rem;
  height: 3.02604rem;
}
.addresinp_view .addresinp_view_title[data-v-49bb0f52] {
  position: relative;
  height: 0.3125rem;
  background: #262626;
  line-height: 0.3125rem;
  padding: 0px 0.19792rem;
  font-size: 0.10417rem;
  font-weight: 800;
  color: #FFFFFF;
}
.addresinp_view .addresinp_view_title[data-v-49bb0f52]::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 0.14583rem;
  background-color: #D5A223;
}
.addresinp_view .addresinp_view_form[data-v-49bb0f52] {
  padding: 0.25521rem 0.20313rem 0.15625rem;
  background: #2B2B2B;
}
.addresinp_view .addresinp_view_form .addresinp_view_row[data-v-49bb0f52] {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0.20833rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox[data-v-49bb0f52] {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox > div[data-v-49bb0f52] {
  margin-right: 0.09375rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox > div[data-v-49bb0f52]:last-child {
  margin-right: 0px;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox .xxdz[data-v-49bb0f52] {
  margin-right: 0.15625rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox input[data-v-49bb0f52] {
  flex: 1;
  height: 0.3125rem;
  background: #3A3A3A;
  border-radius: 0.04167rem;
  border: 1px solid #666666;
  text-indent: 0.10417rem;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #FFFFFF;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox input[data-v-49bb0f52]::placeholder {
  text-indent: 0.10417rem;
  font-size: 0.08333rem;
  color: #666666;
}
.addresinp_view .addresinp_view_form .addresinp_view_row button[data-v-49bb0f52] {
  width: 0.9375rem;
  height: 0.3125rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.10417rem;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}
.addresinp_view .addresinp_view_form .addresinp_view_row button[data-v-49bb0f52]:hover {
  background: #f3af04;
}
.addresinp_view .addresinp_view_form .gap .inputbox[data-v-49bb0f52] {
  margin-right: 0.26042rem;
}
.addresinp_view .addresinp_view_form .gap .inputbox[data-v-49bb0f52]:last-child {
  margin-right: 0px;
}
.backBtn[data-v-49bb0f52] {
  display: flex;
  align-items: center;
}
.backBtn .back[data-v-49bb0f52] {
  width: 0.78125rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.11458rem;
  color: #fff;
  margin-bottom: 0.10417rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.backBtn .back .el-icon-back[data-v-49bb0f52] {
  font-size: 0.16667rem;
  color: #fff;
  line-height: 1;
  margin-right: 0.02604rem;
}
