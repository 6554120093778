.order_item[data-v-59040b62] {
  width: 100%;
  background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
  border-radius: 0.15625rem;
  margin-bottom: 0.10417rem;
  border: 0.01563rem solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 0.08333rem;
  font-weight: 500;
}
.order_item .order_item_head[data-v-59040b62] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.32813rem;
  padding: 0px 0.15625rem;
}
.order_item .order_item_head .order_item_head_title span[data-v-59040b62] {
  margin-right: 0.26042rem;
}
.order_item .order_item_head .order_item_head_icon[data-v-59040b62] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_item .order_item_head .order_item_head_icon span[data-v-59040b62] {
  margin-right: 0.15625rem;
}
.order_item .order_item_head .order_item_head_icon i[data-v-59040b62] {
  width: 0.07292rem;
  height: 0.08333rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAhCAYAAADOHBvaAAAAAXNSR0IArs4c6QAAAgtJREFUWEftV7F12zAQ/YcCUhd7g2SCKBNEniD2BLEaAqyiTBBnA6cioEb2BNYGsSeIMkG8QeROTwUu7xhSoSjyCZT14kYoyf/vg5+Hwx0hYk2n05PVavUFwCWAkxbKTGs9Go1Gi4iQoBiQc+6aiD4BeAIwb+AMALxi5ntr7VlMzFjhX0T0Wmt92vRFhSO/RbANU99MlLD3noVojGnFl5gQwlmapve7vnodaDKZ5HY1EcRCeU5Ew7aAJYaZx0qppt+BJEkeSj6JYAjhTqzctcsDvF+EEC7EEXLOzYjowwGCxoZYGGNOqfw3saxD4Ijo3YsISwK+qPDVIezrEiOEcBN1jrsEjcWuhaUsAngbS9wHp5T6nCRJfsbXwt57KRLv9wkYy6lWtaPwltXM/LMop/U6LnW3jn9i5kciasyVaKtF1Fo78N6fA7gr/yUzf7PWjiv3dPnqwhgzc87Nm8SjhQE8GGOGWZYNlVLfK0n01Rhz5b2XGiCdSb7KwG2JehTObWqx52h1mUfH5Kofpx8ApHncWF2OkxCvmXlQ7TCL6nQDYFybLGYAHovnW3dHV+HYy2cnrlH4f3SbIYQ3aZqKI//u4yzLLpVS053b3h9wa4yRoS9fG61PW3HfX+svUy6bXq83rM5dG8LF8CUt0MfnilX4t1rrcX3Ya2z2ZAPL5XLrOHTdTL/fn7fNy38AWe3AHrGG35QAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.order_item .order_item_body[data-v-59040b62] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #cccccc7d;
  border-bottom: 1px solid #cccccc7d;
}
.order_item .order_item_body .order_item_body_info[data-v-59040b62] {
  flex: 1;
  border-right: 1px solid #cccccc7d;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata[data-v-59040b62] {
  display: flex;
  align-items: center;
  padding: 0.09896rem 0.11979rem;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_img[data-v-59040b62] {
  width: 1.04167rem;
  height: 0.83333rem;
  border-radius: 0.07292rem;
  margin-right: 0.10938rem;
  overflow: hidden;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_img img[data-v-59040b62] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_text[data-v-59040b62] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 0.83333rem;
  font-size: 0.15625rem;
  font-weight: 800;
  line-height: 0.1875rem;
  color: #FFFFFF;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_text p span[data-v-59040b62] {
  font-size: 0.11458rem;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 0.16667rem;
  padding: 0.02604rem 0.05208rem;
  background: #2B475A;
  border-radius: 0.02604rem;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_text div[data-v-59040b62] {
  width: 2.60417rem;
  font-size: 0.11458rem;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order_item .order_item_body .order_item_body_info .order_item_body_infodata .info_text div .redtext[data-v-59040b62] {
  font-size: 0.15625rem;
  font-weight: 800;
  color: #FF0000;
}
.order_item .order_item_body .order_item_body_btn[data-v-59040b62] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.28646rem;
  height: 100%;
  font-size: 0.07292rem;
}
.order_item .order_item_body .order_item_body_btn p[data-v-59040b62] {
  margin: 0.05208rem 0px;
}
.order_item .order_item_body .order_item_body_btn button[data-v-59040b62] {
  width: 0.78125rem;
  height: 0.26042rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
}
.order_item .order_item_body .order_item_body_btn button[data-v-59040b62]:hover {
  background: #f7b203;
}
.order_item .order_item_body .order_item_body_btn .text_date[data-v-59040b62] {
  color: #FF0000;
}
.order_item .order_item_body .order_item_body_btn .text_res[data-v-59040b62] {
  font-size: 0.125rem;
  font-weight: 500;
  color: #D5A223;
}
.order_item .order_item_foot[data-v-59040b62] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.11979rem 0px 0.17708rem;
  height: 0.53646rem;
  font-size: 0.15625rem;
  font-weight: 800;
  color: #FF0000;
}
.order_item .order_item_foot .order_item_foot_btn[data-v-59040b62] {
  width: 0.83333rem;
  height: 0.3125rem;
  background: #D5A223;
  border-radius: 0.20833rem;
  font-size: 0.14583rem;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 0.3125rem;
  text-align: center;
}
.bigredtext[data-v-59040b62] {
  font-size: 0.08333rem;
  font-weight: 800;
  color: #FF0000;
}
