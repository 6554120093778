.goodsitem[data-v-6069a16c] {
  position: relative;
  width: 1.77083rem;
}
.goodsitem .goodsitemimg[data-v-6069a16c] {
  height: 1.40625rem;
  border-radius: 0.07292rem;
  overflow: hidden;
}
.goodsitem .goodsitemimg img[data-v-6069a16c] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.goodsitem .goodsname[data-v-6069a16c] {
  width: 100%;
  font-size: 0.15625rem;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 0.15625rem;
  margin: 0.07813rem 0px;
}
.goodsitem .goodsname p[data-v-6069a16c] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goodsitem .goodsPrice[data-v-6069a16c] {
  display: flex;
  align-items: baseline;
}
.goodsitem .goodsPrice .goodsPricetrue[data-v-6069a16c] {
  font-size: 0.20833rem;
  font-weight: 800;
  color: #FF0000;
  margin-right: 0.05208rem;
}
.goodsitem .goodsPrice .goodsPricefalse[data-v-6069a16c] {
  font-size: 0.125rem;
  font-weight: 800;
  color: #666666;
}
.goodsitem .goodsPrice .goodsPricefalse p[data-v-6069a16c] {
  text-decoration: line-through;
}
.goodsitem .canvasbox[data-v-6069a16c] {
  position: absolute;
  top: 0px;
}
