.addres_item[data-v-2e5a4247] {
  position: relative;
  width: 1.45833rem;
  height: 1.09375rem;
  margin-right: 0.17708rem;
  margin-bottom: 0.14583rem;
  background: #383838;
  padding: 0.07813rem;
  color: #fff;
}
.addres_item .addres_name[data-v-2e5a4247] {
  font-size: 0.10417rem;
  font-weight: 800;
}
.addres_item .addres_number[data-v-2e5a4247] {
  line-height: 0.08333rem;
  margin: 0.05208rem 0px;
  font-size: 0.08333rem;
  font-weight: 800;
}
.addres_item .addres_info[data-v-2e5a4247] {
  font-size: 0.08333rem;
  margin-bottom: 0.05208rem;
  color: #CCCCCC;
}
.addres_item .addres_info p[data-v-2e5a4247] {
  max-height: 0.21875rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.addres_item .addres_info span[data-v-2e5a4247] {
  margin-right: 0.08854rem;
}
.addres_item .addres_i[data-v-2e5a4247] {
  position: absolute;
  left: 0.07813rem;
  bottom: 0.07813rem;
  font-size: 0.08333rem;
  font-weight: 400;
  color: #666666;
}
.addres_item .addres_i .activeadd[data-v-2e5a4247] {
  color: #FF0000;
  user-select: none;
}
.active_item[data-v-2e5a4247] {
  border: 1px solid #D5A223;
}
.addres_item[data-v-2e5a4247]:nth-child(4n) {
  margin-right: 0px;
}
