button[data-v-05f842e0] {
  cursor: pointer;
}
.addressList[data-v-05f842e0] {
  min-height: 4.46354rem;
}
.addressList .addressList_item[data-v-05f842e0] {
  display: flex;
  align-items: center;
  height: 0.76042rem;
  padding: 0px 0.21875rem;
  height: 0.88542rem;
  background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
  border-radius: 0.125rem;
  border: 0.01563rem solid rgba(255, 255, 255, 0.2);
  font-size: 0.13542rem;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0.10417rem;
}
.addressList .addressList_item > div[data-v-05f842e0] {
  flex: 1;
  display: flex;
  align-items: center;
}
.addressList .addressList_item > div i[data-v-05f842e0] {
  width: 0.10417rem;
  height: 0.10417rem;
  background: #5A5A5A;
  border-radius: 50%;
  margin-right: 0.04688rem;
  cursor: pointer;
}
.addressList .addressList_item > div .activeicon[data-v-05f842e0] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAPCAYAAAAPr1RWAAAAAXNSR0IArs4c6QAAAKRJREFUOE+t09ERhCAMBNDdCmzhSrIELcUK7jpQS7GyODDkBhEkOPDhF/M2JIbofERkAvAFsLCnHeA1mP3wBN5JTl0qz8GueorICGAgub9pUQlWXAK6kZxbAp5gxd10dQjmgBrscfdJLlYDLPAfbwmwwhfcEtAC3/CngFY4i+cCABzR0P2CWP6q4hIllaplhouVq/SmFfGLqusfNvhD8mdpRXznBL0CbPq766TXAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 0.05729rem 0.03646rem;
  background-color: #D5A223;
}
.addressList .addressList_item > div .deletebtn[data-v-05f842e0] {
  background-color: #444444;
  margin-right: 0px;
}
.addressList .addressList_item > div[data-v-05f842e0]:first-child {
  flex: 2;
  align-items: start;
  flex-direction: column;
}
.addressList .addressList_item > div:first-child p[data-v-05f842e0] {
  margin: 0.02604rem 0px;
}
.addressList .addressList_item > div:first-child p span[data-v-05f842e0] {
  font-size: 0.13542rem;
  color: #999999;
  margin-right: 0.07813rem;
}
.addressList .addressList_item > div:last-child .editText[data-v-05f842e0] {
  width: 0.16667rem;
  height: 0.17708rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABGCAYAAACT8vn9AAAAAXNSR0IArs4c6QAABQxJREFUeF7tnM113DYQgDE8kHuLU4GlCiJVELkCWxXEexG4J9sVRKogyomELitV4E0F2VTgTQWxK8jqKB0AcxiQj8vlz4AESEaPPOggDQeYjwNgMBgIWMUTx/FbAHjPGLtgjL2qknHwu78459he/gghtkqp1wBwzzm/cdBmqhKKitfr9aunp6fPAHDQGVeNl/RWQmCM/azldlLKy9Vq9dV2f3IICOD5+flPxtiZ7UaI+togoJo9ALy5urraEXWSxFIIEwCA3aBASI2SUi5Xq9U9yUKCUAohjuNbAPhAkHcpQoagO3Fta56AKIpOPM/7x6V1RN2mEJhS6j4MwyVRf60YTMQLjIZD0RobIEAI8WXEybBoj7EnZC/3BYEQVF93svT+jnN+XtSFcUJhiWxspg+IKUFgyUR3ELcIIXAF+MUA8g3n/NpAPhWdFATf939cLpf7zIgoit57nrc2MUpKeWoaUE0KAmPsknO+KQ0JjBBfU0FIKd+sViscRuRnahAeOOe4Z8mfu7u7M6UUgiGBeAkQ9lLKc1N3Jn/yGsGpeQJ2c8M5v+xrmMn7U4RgLRKkgpgkBN35jZTy0xBDY8oQsg+J22ZcIUjb52SJfDAF93+AQPXqVO4lrA5GBlcJzxBmT/jPL2ZPmCHMnpDPkfNwGGA4PCanUbdSSqNtau81jzFMBGOiZPRd5LfE+AvTSMwCgFSFPhm7B4C3bTqdDYcuits6a/p3fUCE4fMPTe926SslbH7knA91KNvIhpJzdAXhKBVu+hVtyQshcG74dXBPUEp9DcPw1JYhffSM6QmdQtE+xla9qyfHL0mtwsngnoANojcEQXBeTIfbNrJNXxzHa1040ijqak7IGt0rpa49zyMlN9qMov49OVnCL/+RelToGgK136PKzRAGCJtH/cLUxmdPmD1hzifkowUAzk2r2yh7B+pwHF2ua1Xbi4HQBEDvQD8opbBIdRMEwUMx8DOF8E0ptQOAoQKmk+RY/gwAfmpxs0/JTve2LtyuKFI9KAo1gdCpFMbGGBFCvGOMYelOVS7hqKah2KYQoq7IY+/7/il6BAmCUur3MAwxdB3tiaLowvM8LDsuPo0AUFAXeWBKsBYgCUK5lmgsEqVqtlYAWT+bQGCxGAXCFJMqtQDQYCnlZ8/zLotLZR0IKoSj+sIRPQEzSyfluqbSF8chg+nAo4r4ChApTIonsKkMByzpq6tu1wZmAPLtf/lqQAYiWS63YRjihEurY5zCxNjkfTUAakFgUftisdhnsQLJE7S2W9/3b8bILmGwU9duC4BaEEWoJhCy93ZKqbzq1PX8AADg+/67KghEADmIuvLALhBc253rV0r9HQTBhQUAqLN2RZkshKEAkCfGwT69bmhIAJOEMDSADIJRFblLrxgDQAbB9GKFKw6PUsqzquN/w1WgcRKs6jzehqvanbkytE7vI97KrUqLuQaQegL+iON4QymAcERmVAA5BJ1+wmwRqSTGIoxaAPq+Jt7Uo9ZGkLfW5f7nF69akg8W7c5V1QJACcoxfKFTnQHknpAp08ffW0JOry+URgCGt3Z7ATiCkFkmhMBUGu7dG+uDupJoOxvQ7f9G0N8bQC2EAgzcb9v+VwKYpDm48VY2ljhRWwHQCoHwJayL6En63xbF1gBMEkJL3JIWlHa5BdsE9eBarvXP2kFhVYWaUuoPvBu5WCw2LpI6U4SA89BHfdK19X1/68Lw4vf5DqLptXRx6/wvAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
  margin-right: 0.19271rem;
}
.addressList .addressList_item > div:last-child .clearText[data-v-05f842e0] {
  width: 0.15625rem;
  height: 0.17708rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAABFCAYAAAASJFVGAAAAAXNSR0IArs4c6QAAAz9JREFUeF7tW8152zAMJXXQtekEbTZwJ2gyQesJal9M6dRu0HiCpicJvjiZoO4ETSZoR4g38NkHsoY/KZVkyiL5UY7ogmeQfA8gwR8AnPXUAOATY+wLY2zkMMVGKbVSSs3TNH1y6N/ZhXdKWAosl8uL7Xb7y5HwwWxSymmapneWMDrFvRMHgN++SFfQj4UQq042FgJeiWdZNomiaGkxv5GoUuopSZJLI2FDIa/E8zxfcc4/VOa+F0JMDLE8iwEALm30Ec+Nc/5uNpv9sR2rTd4rcQB4YIy9LyeL4/j1dDrduIAFAFXt53uv+yZeAyuEcB6/SZwxNhdC3LgoUdfHGZhusCZYIu5gJrL4P6X1s9SLi8dnxtjHHs5hB5v76YJHIWPsoXkL3O/xxWIxUkrh+etyvfSD8DSj3Oz8zhyn4r6vmKfB7z5LeSxyAMCHxDf3oYLruYnj+BKJ93G3Hro2xki8dukYOmJP+OZE3JMmQxmGLB6KpXzhJIv70mQo48y9PktDYb2/soYE1idWIu5TmyGMdWDxLMuuoijCgMA5tUchxFWVEBEvtUEWP6eFzhgt9dKetMdpj9NxdlberTfnds8Yw4/7t83wrk59SqmfnHMM+fYir5mzF+K1GLgutl0FgqSTJMFozb45yGPE9KvleuyFeC1NwyArohYDM7gw2crrdOKfuJTyOk1TTAjYtx6IEHFDxZLFKxqgpe7tykp7nJxb5w+OrZe2lSfnRs6tj48Icm7k3Mi5tT2gbL20rTx5dfLqJ/LqXVUKtku3Jg8A+InxYwgfETVgeZ7fcs4xJ9ZojwPA0R8VpdT3JEkwCXHfDMY/zR7flVhtOOfXWDZR5MRiwPHiCPGNlHKMnxc9yZ+MuOWqexFx/+/xF6FhP+l/S/wgyf8gdlbsO0zsPZvWfE8gMW0OzC6VG0umXp0J87UQAgMXtaYlrimcC1kH2loWLXGDv/FQFLGO43ikK/prTfdqVg2GwrSBs7UYt5V4UauCgcAg93pXSebRBL/Cw2N4KCjyXaRbvXp1uRSWvzUJ/w5gOzxKKW+qsbw2TMYpnWh9KeVkF9fG19GbAZAsIayxoE5KeWdCuOxkTHxARL1A+Qv4LBiIGgSrSQAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100% 100%;
}
.addressList .addressList_btn[data-v-05f842e0] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.67708rem;
}
.addressList .addressList_btn button[data-v-05f842e0] {
  width: 1.30208rem;
  height: 0.41667rem;
  background: #D5A223;
  border-radius: 0.20833rem;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
  border: none;
}
.addressinfo[data-v-05f842e0] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
