.container[data-v-cb591c32] {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 7.375rem;
  margin: auto;
  padding-top: 0.20833rem;
}
.container .navbar[data-v-cb591c32] {
  width: 1.5625rem;
}
.container .container_main[data-v-cb591c32] {
  width: 5.61979rem;
}
.container .container_main[data-v-cb591c32] .swiperView {
  width: 100%;
  height: 1.04167rem;
  border-radius: 0.10417rem;
  margin-bottom: 0.18229rem;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper {
  width: 100%;
  height: 100%;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .item {
  position: relative;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .item .imgs {
  width: 100%;
  height: 100%;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .item .imgs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .swiper-pagination {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 0.10417rem 0;
  justify-content: center;
  z-index: 99;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .swiper-pagination .swiper-pagination-bullet {
  display: flex;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0 0.07813rem;
  background-color: #f00;
  border-radius: 0.0625rem;
  z-index: 99;
  box-shadow: 1px 1px 0.01563rem #666;
}
.container .container_main[data-v-cb591c32] .swiperView .swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}
.container .container_main .notzhanpin[data-v-cb591c32] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.14583rem;
  color: #ffffff;
}
.container .container_main .goodslist[data-v-cb591c32] {
  display: grid;
  grid-template-columns: 1.77083rem 1.77083rem 1.77083rem;
  gap: 0.15625rem;
}
.container .container_main[data-v-cb591c32] .paging {
  width: 100%;
  margin-top: 0.10417rem;
}
.container .container_main[data-v-cb591c32] .paging .el-pagination {
  text-align: center;
}
.container .container_main[data-v-cb591c32] .paging .el-pager li {
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
  background-color: white;
}
.container .container_main[data-v-cb591c32] .paging .el-pager li:not(.disabled).active {
  background-color: #d71a18;
  color: #fff;
}
.container .container_main[data-v-cb591c32] .paging .btn-next,
.container .container_main[data-v-cb591c32] .paging .btn-prev {
  background-color: white;
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
}
