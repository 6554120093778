.menuComponents[data-v-6ac66fad] {
  position: relative;
  border-top: 1px solid;
}
.menuComponents .menubox[data-v-6ac66fad] {
  height: 0.78125rem;
  overflow: hidden;
  margin-top: 0.26042rem;
}
.menuComponents .menubox .menuView[data-v-6ac66fad] {
  overflow-x: auto;
}
.menuComponents .menubox .menuView .menu_content[data-v-6ac66fad] {
  padding: 0px 0.26042rem;
  white-space: nowrap;
  height: 1.5625rem;
}
