/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.95s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.125s;
}
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-0.15625rem);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(0.15625rem);
}
/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.125s;
}
.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(0.10417rem);
}
.breadcrumb-move {
  transition: all 0.125s;
}
.breadcrumb-leave-active {
  position: absolute;
}
body::-webkit-scrollbar-track-piece {
  background: #333c58;
}
body::-webkit-scrollbar {
  width: 0.03125rem;
}
body::-webkit-scrollbar-thumb {
  background: #10161b;
  border-radius: 0.10417rem;
}
.fix-header {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 1rem;
  width: calc(100vw - 0.7rem);
  padding: 0.25rem 0.25rem;
  background-color: transparent;
  z-index: 999;
}
.fix-header.header-center {
  padding: 0.25rem 0 0.25rem 0.25rem;
}
.fix-header.header-center .title {
  text-align: center;
}
.fix-header .title {
  width: 100%;
  text-align: left;
  font-size: 0.34rem;
  font-weight: 800;
  color: #FFFFFF;
}
.fix-header .back {
  position: relative;
  z-index: 1;
}
.fix-header .back .el-icon-arrow-left {
  font-size: 0.36rem;
  font-weight: 700;
  color: #fff;
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
}
.empty img {
  display: block;
  width: 2.81rem;
}
.empty .txt {
  margin-top: 0.35rem;
  font-size: 0.34rem;
  font-weight: 700;
  color: #999;
}
.no_more {
  width: 100%;
  margin-bottom: 0.1rem;
  font-size: 0.26rem;
  color: #666;
  text-align: center;
}
