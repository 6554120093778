.listViewitem[data-v-a70b8154] {
  background-color: #1D2533;
  border-radius: 0.20833rem;
  margin-bottom: 0.20833rem;
}
.listViewitem .orderinfo[data-v-a70b8154] {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2.70833rem;
  padding: 0.1875rem 0.20833rem;
}
.listViewitem .orderinfo .orderinfoimg[data-v-a70b8154] {
  width: 2.8125rem;
  height: 2.34375rem;
  background: #fff;
  margin-right: 0.3125rem;
  border-radius: 0.15625rem;
  overflow: hidden;
}
.listViewitem .orderinfo .orderinfoimg img[data-v-a70b8154] {
  width: 100%;
  height: 100%;
  object-fit: cove;
}
.listViewitem .orderinfo .orderinfo_Comtent[data-v-a70b8154] {
  width: 50%;
}
.listViewitem .orderinfo .orderinfo_Comtent .orderinfo_Comtent_Title[data-v-a70b8154] {
  font-size: 0.29167rem;
  font-weight: 800;
  color: #FFFFFF;
  margin-bottom: 0.26042rem;
}
.listViewitem .orderinfo .orderinfo_Comtent .orderinfo_gg[data-v-a70b8154] {
  display: flex;
  flex-wrap: wrap;
}
.listViewitem .orderinfo .orderinfo_Comtent .orderinfo_gg span[data-v-a70b8154] {
  padding: 0.05208rem 0.17708rem;
  background-color: #303A4B;
  margin-right: 0.15625rem;
  margin-top: 0.15625rem;
  border-radius: 0.07813rem;
  font-size: 0.22917rem;
  font-weight: 500;
  color: #999999;
}
.listViewitem .orderinfo .orderinfo_num_Price[data-v-a70b8154] {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.listViewitem .orderinfo .orderinfo_num_Price .orderinfo_Price[data-v-a70b8154] {
  font-size: 0.52083rem;
  font-weight: 800;
  color: #FF0021;
}
.listViewitem .orderinfo .orderinfo_num_Price .orderinfo_Price span[data-v-a70b8154] {
  font-size: 0.22917rem;
}
.listViewitem .orderinfo .orderinfo_num_Price .orderinfo_num[data-v-a70b8154] {
  font-size: 0.25rem;
  font-weight: 800;
  color: #F88503;
}
.listViewitem .orderinfo .delOrderbox[data-v-a70b8154] {
  position: absolute;
  top: 0.26042rem;
  right: 0.26042rem;
  width: 0.26042rem;
  height: 0.36458rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAABFCAYAAAASJFVGAAAAAXNSR0IArs4c6QAAAz9JREFUeF7tW8152zAMJXXQtekEbTZwJ2gyQesJal9M6dRu0HiCpicJvjiZoO4ETSZoR4g38NkHsoY/KZVkyiL5UY7ogmeQfA8gwR8AnPXUAOATY+wLY2zkMMVGKbVSSs3TNH1y6N/ZhXdKWAosl8uL7Xb7y5HwwWxSymmapneWMDrFvRMHgN++SFfQj4UQq042FgJeiWdZNomiaGkxv5GoUuopSZJLI2FDIa/E8zxfcc4/VOa+F0JMDLE8iwEALm30Ec+Nc/5uNpv9sR2rTd4rcQB4YIy9LyeL4/j1dDrduIAFAFXt53uv+yZeAyuEcB6/SZwxNhdC3LgoUdfHGZhusCZYIu5gJrL4P6X1s9SLi8dnxtjHHs5hB5v76YJHIWPsoXkL3O/xxWIxUkrh+etyvfSD8DSj3Oz8zhyn4r6vmKfB7z5LeSxyAMCHxDf3oYLruYnj+BKJ93G3Hro2xki8dukYOmJP+OZE3JMmQxmGLB6KpXzhJIv70mQo48y9PktDYb2/soYE1idWIu5TmyGMdWDxLMuuoijCgMA5tUchxFWVEBEvtUEWP6eFzhgt9dKetMdpj9NxdlberTfnds8Yw4/7t83wrk59SqmfnHMM+fYir5mzF+K1GLgutl0FgqSTJMFozb45yGPE9KvleuyFeC1NwyArohYDM7gw2crrdOKfuJTyOk1TTAjYtx6IEHFDxZLFKxqgpe7tykp7nJxb5w+OrZe2lSfnRs6tj48Icm7k3Mi5tT2gbL20rTx5dfLqJ/LqXVUKtku3Jg8A+InxYwgfETVgeZ7fcs4xJ9ZojwPA0R8VpdT3JEkwCXHfDMY/zR7flVhtOOfXWDZR5MRiwPHiCPGNlHKMnxc9yZ+MuOWqexFx/+/xF6FhP+l/S/wgyf8gdlbsO0zsPZvWfE8gMW0OzC6VG0umXp0J87UQAgMXtaYlrimcC1kH2loWLXGDv/FQFLGO43ikK/prTfdqVg2GwrSBs7UYt5V4UauCgcAg93pXSebRBL/Cw2N4KCjyXaRbvXp1uRSWvzUJ/w5gOzxKKW+qsbw2TMYpnWh9KeVkF9fG19GbAZAsIayxoE5KeWdCuOxkTHxARL1A+Qv4LBiIGgSrSQAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100% 100%;
}
.listViewitem .orderinfobtn[data-v-a70b8154] {
  height: 1.04167rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.26042rem;
  font-size: 0.25rem;
  font-weight: 800;
  color: #FFFFFF;
  border-top: 1px solid #cccccc54;
}
.listViewitem .orderinfobtn .datetext[data-v-a70b8154] {
  color: #FF0021;
}
.listViewitem .orderinfobtn .orderinfobtn_btn[data-v-a70b8154] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.listViewitem .orderinfobtn .orderinfobtn_btn > div[data-v-a70b8154] {
  padding: 0.125rem 0.1875rem;
  background-color: #3A3A47;
  border-radius: 0.3125rem;
  margin-right: 0.20833rem;
}
.listViewitem .orderinfobtn .orderinfobtn_btn > div[data-v-a70b8154]:last-child {
  margin-right: 0px;
}
.listViewitem .orderinfobtn .orderinfobtn_btn .itembtn[data-v-a70b8154] {
  background: #F98E14;
}
