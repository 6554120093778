.popUpView[data-v-05b4a4ad] {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  z-index: 999;
}
.popUpView .box[data-v-05b4a4ad] {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #3a3a47;
  border-radius: 0.41667rem 0.41667rem 0px 0px;
}
.popUpView .box .box_head[data-v-05b4a4ad] {
  display: flex;
  justify-content: space-between;
  height: 0.9375rem;
  padding: 0px 0.26042rem;
  border-bottom: 1px solid #F88503;
  line-height: 0.9375rem;
  font-size: 0.29167rem;
  font-weight: 800;
  color: #fff;
}
.popUpView .box .box_head .box_head_btn[data-v-05b4a4ad] {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.popUpView .box .box_head .box_head_btn > div[data-v-05b4a4ad] {
  width: 1.04167rem;
  height: 0.46875rem;
  background: #fff;
  text-align: center;
  line-height: 0.46875rem;
  margin-right: 0.26042rem;
  border-radius: 0.10417rem;
}
.popUpView .box .box_head .box_head_btn .btn_yse[data-v-05b4a4ad] {
  color: #fff;
  background: #F88503;
}
.popUpView .box .box_body[data-v-05b4a4ad] {
  width: 100%;
  height: 40vh;
  overflow-y: auto;
}
.popUpView .box .box_body[data-v-05b4a4ad] .el-tree {
  background: #3a3a47;
  color: #fff;
}
.popUpView .box .box_body[data-v-05b4a4ad] .el-tree .el-tree-node__content:hover,
.popUpView .box .box_body[data-v-05b4a4ad] .el-tree .el-upload-list__item:hover {
  background-color: #F88503;
}
