.orderlist[data-v-4905c348] {
  height: 95vh;
  background: #3A3A47;
}
.orderlist .order_typeNavbar[data-v-4905c348] {
  display: flex;
  height: 1.04167rem;
  padding: 0px 0.26042rem;
  font-size: 0.29167rem;
  font-weight: 800;
  line-height: 1.04167rem;
  color: #FFFFFF;
  border-bottom: 1px solid #cccccc50;
}
.orderlist .order_typeNavbar > div[data-v-4905c348] {
  position: relative;
  flex: 1;
  text-align: center;
}
.orderlist .order_typeNavbar .activetype[data-v-4905c348] {
  color: #F88503;
}
.orderlist .order_typeNavbar .activetype[data-v-4905c348]::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 0.58333rem;
  height: 0.04167rem;
  background: #F88503;
  border-radius: 0.02083rem;
}
.orderlist .notlistText[data-v-4905c348] {
  height: 1.04167rem;
  font-size: 0.27083rem;
  font-weight: 800;
  line-height: 1.04167rem;
  text-align: center;
  color: #ffffff;
}
.orderlist .listView[data-v-4905c348] {
  width: 100%;
  height: 91%;
  padding: 0.3125rem 0.26042rem;
  overflow-y: auto;
}
.Popup_box[data-v-4905c348] {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000052;
  z-index: 9999;
}
.Popup_box .code_box[data-v-4905c348] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 5.20833rem;
  height: 6.25rem;
  background-color: #fff;
  border-radius: 0.41667rem;
  font-size: 0.29167rem;
  font-weight: 800;
  color: #333;
}
.Popup_box .code_box .codeImgBox[data-v-4905c348] {
  width: 4.6875rem;
  height: 4.6875rem;
}
.Popup_box .code_box .codeImgBox img[data-v-4905c348] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Popup_box .code_box p[data-v-4905c348] {
  font-size: 0.25rem;
  color: #cccccc;
}
.Popup_box .code_box .clearIcon[data-v-4905c348] {
  position: absolute;
  top: 0.15625rem;
  right: 0.20833rem;
}
.confirmview[data-v-4905c348] {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #00000067;
  z-index: 9999;
}
.confirmview .confirmbox[data-v-4905c348] {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 4.6875rem;
  height: 2.86458rem;
  background-color: #3A3A47;
  border-radius: 0.20833rem;
}
.confirmview .confirmbox .confirmTitle[data-v-4905c348] {
  font-size: 0.33333rem;
  font-weight: 800;
  color: #fff;
}
.confirmview .confirmbox .confirmBtn[data-v-4905c348] {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.confirmview .confirmbox .confirmBtn div[data-v-4905c348] {
  width: 1.04167rem;
  height: 0.57292rem;
  text-align: center;
  line-height: 0.57292rem;
  font-size: 0.25rem;
  border-radius: 0.10417rem;
}
.confirmview .confirmbox .confirmBtn > div[data-v-4905c348]:nth-child(1) {
  background-color: #fff;
}
.confirmview .confirmbox .confirmBtn > div[data-v-4905c348]:nth-child(2) {
  background-color: #F88503;
  color: #fff;
}
