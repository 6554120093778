.container[data-v-da68e15a] {
  height: 100vh;
}
.container .tabs[data-v-da68e15a] {
  display: flex;
  border-bottom: 1px solid #ffffff20;
}
.container .tabs .item[data-v-da68e15a] {
  min-width: 0.9375rem;
  font-size: 0.125rem;
  font-weight: 700;
  text-align: center;
  color: #ccc;
  line-height: 0.41667rem;
  cursor: pointer;
}
.container .tabs .item.cur[data-v-da68e15a] {
  position: relative;
  color: #D6A324;
}
.container .tabs .item.cur[data-v-da68e15a]::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(50% - 0.26042rem);
  width: 0.52083rem;
  height: 2px;
  background: #D6A324;
}
.container .list[data-v-da68e15a] {
  padding: 0.20833rem 0.23438rem;
  margin: 0 0.10417rem 0 0;
  height: calc(100% - 1.04167rem);
  overflow-y: scroll;
}
.container .list[data-v-da68e15a]::-webkit-scrollbar {
  width: 0.04167rem;
  height: 0.04167rem;
}
.container .list[data-v-da68e15a]::-webkit-scrollbar-track {
  background-color: #4B4B4B;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .list[data-v-da68e15a]::-webkit-scrollbar-thumb {
  background-color: #D5A223;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.container .list .noMore[data-v-da68e15a] {
  float: left;
  width: 100%;
  height: 0.10417rem;
  color: #ccc;
  text-align: center;
}
.container .list .item[data-v-da68e15a] {
  float: left;
  width: calc(25% - 0.15625rem);
  height: 0.78125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.15625rem;
  margin: 0 0.20833rem 0.15625rem 0;
  background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png) no-repeat center;
  background-size: cover;
  border-radius: 0.07292rem;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}
.container .list .item.cur[data-v-da68e15a] {
  background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
}
.container .list .item[data-v-da68e15a]:nth-child(4n) {
  margin-right: 0;
}
.container .list .item .name[data-v-da68e15a] {
  margin-bottom: 0.08333rem;
  font-size: 0.15625rem;
  font-weight: 700;
  color: #fff;
}
.container .list .item .realName[data-v-da68e15a] {
  font-size: 0.125rem;
  font-weight: 700;
  color: #1752F8;
}
.container .footer[data-v-da68e15a] {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0.625rem;
  background: linear-gradient(180deg, #333C58 0%, #1B2731 100%);
  padding: 0 0.23438rem;
}
.container .footer .choose[data-v-da68e15a] {
  display: flex;
  align-items: center;
  font-size: 0.15625rem;
  font-weight: 700;
  color: #fff;
}
.container .footer .choose .num[data-v-da68e15a] {
  display: flex;
  font-size: 0.15625rem;
  color: #D6A324;
}
.container .footer .btns[data-v-da68e15a] {
  display: flex;
}
.container .footer .btns .btn[data-v-da68e15a] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.15625rem;
  min-width: 0.83333rem;
  height: 0.3125rem;
  margin-left: auto;
  background: linear-gradient(109deg, #D5A223 0%, #FFD263 100%);
  border-radius: 0.15625rem;
  color: #fff;
  font-size: 0.13542rem;
  font-weight: 700;
  cursor: pointer;
}
