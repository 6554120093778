.goodslistbox[data-v-168e5346] {
  width: 100%;
  height: 86vh;
  padding: 0.36458rem 0.26042rem 1.04167rem;
  overflow-y: auto;
}
.goodslistbox .notdata[data-v-168e5346] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 0.29167rem;
  font-weight: 800;
  color: #fff;
}
.goodslistbox .goodslistview[data-v-168e5346] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.20833rem;
}
.goodslistbox .goodslistview .goodsitem[data-v-168e5346] {
  color: #fff;
}
.goodslistbox .goodslistview .goodsitem .goodsitemimg[data-v-168e5346] {
  height: 2.8125rem;
  border-radius: 0.18229rem;
  background-color: #fff;
}
.goodslistbox .goodslistview .goodsitem .goodsitemimg img[data-v-168e5346] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.goodslistbox .goodslistview .goodsitem .goodstitle[data-v-168e5346] {
  font-size: 0.3125rem;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 0.33333rem;
  margin: 0.20833rem 0px;
}
.goodslistbox .goodslistview .goodsitem .goodsPrice[data-v-168e5346] {
  font-size: 0.41667rem;
  font-weight: 800;
  color: #FF0000;
}
.goodslistbox .goodslistview .goodsitem .goodsPrice i[data-v-168e5346] {
  font-size: 0.25rem;
  font-style: normal;
}
.goodslistbox .goodslistview .goodsitem .goodsPrice span[data-v-168e5346] {
  font-size: 0.25rem;
  font-weight: 800;
  color: #666666;
  text-decoration: line-through;
}
