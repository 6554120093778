.order_item[data-v-6f5c515c] {
  width: 100%;
  background: #2B2B2B;
  margin-bottom: 0.10417rem;
  color: #ffffff;
  font-size: 0.08333rem;
  font-weight: 500;
}
.order_item .order_item_head[data-v-6f5c515c] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.32813rem;
  padding: 0px 0.15625rem;
  border-bottom: 1px solid #cccccc7d;
}
.order_item .order_item_head .order_item_head_title span[data-v-6f5c515c] {
  margin-right: 0.26042rem;
}
.order_item .order_item_head .order_item_head_icon[data-v-6f5c515c] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order_item .order_item_head .order_item_head_icon span[data-v-6f5c515c] {
  margin-right: 0.15625rem;
}
.order_item .order_item_head .order_item_head_icon i[data-v-6f5c515c] {
  width: 0.07292rem;
  height: 0.08333rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAhCAYAAADOHBvaAAAAAXNSR0IArs4c6QAAAgtJREFUWEftV7F12zAQ/YcCUhd7g2SCKBNEniD2BLEaAqyiTBBnA6cioEb2BNYGsSeIMkG8QeROTwUu7xhSoSjyCZT14kYoyf/vg5+Hwx0hYk2n05PVavUFwCWAkxbKTGs9Go1Gi4iQoBiQc+6aiD4BeAIwb+AMALxi5ntr7VlMzFjhX0T0Wmt92vRFhSO/RbANU99MlLD3noVojGnFl5gQwlmapve7vnodaDKZ5HY1EcRCeU5Ew7aAJYaZx0qppt+BJEkeSj6JYAjhTqzctcsDvF+EEC7EEXLOzYjowwGCxoZYGGNOqfw3saxD4Ijo3YsISwK+qPDVIezrEiOEcBN1jrsEjcWuhaUsAngbS9wHp5T6nCRJfsbXwt57KRLv9wkYy6lWtaPwltXM/LMop/U6LnW3jn9i5kciasyVaKtF1Fo78N6fA7gr/yUzf7PWjiv3dPnqwhgzc87Nm8SjhQE8GGOGWZYNlVLfK0n01Rhz5b2XGiCdSb7KwG2JehTObWqx52h1mUfH5Kofpx8ApHncWF2OkxCvmXlQ7TCL6nQDYFybLGYAHovnW3dHV+HYy2cnrlH4f3SbIYQ3aZqKI//u4yzLLpVS053b3h9wa4yRoS9fG61PW3HfX+svUy6bXq83rM5dG8LF8CUt0MfnilX4t1rrcX3Ya2z2ZAPL5XLrOHTdTL/fn7fNy38AWe3AHrGG35QAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.order_item .order_item_body[data-v-6f5c515c] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0.91667rem;
}
.order_item .order_item_body .order_item_body_info[data-v-6f5c515c] {
  flex: 1;
  display: flex;
  align-items: center;
}
.order_item .order_item_body .order_item_body_info > div[data-v-6f5c515c] {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order_item .order_item_body .order_item_body_info > div p[data-v-6f5c515c] {
  margin: 0.02604rem 0px;
}
.order_item .order_item_body .order_item_body_info > div[data-v-6f5c515c]:first-child {
  flex: 2;
  flex-direction: row;
}
.order_item .order_item_body .order_item_body_info > div:first-child .info_img[data-v-6f5c515c] {
  width: 0.52083rem;
  height: 0.52083rem;
  margin-right: 0.10938rem;
}
.order_item .order_item_body .order_item_body_info > div:first-child .info_img img[data-v-6f5c515c] {
  width: 100%;
  height: 100%;
}
.order_item .order_item_body .order_item_body_info > div:first-child .info_text[data-v-6f5c515c] {
  font-size: 0.09375rem;
  font-weight: 400;
  line-height: 0.1875rem;
  color: #FFFFFF;
}
.order_item .order_item_body .order_item_body_info > div:first-child .info_text span[data-v-6f5c515c] {
  font-size: 0.08333rem;
  font-weight: 400;
  color: #999999;
}
.order_item .order_item_body .order_item_body_btn[data-v-6f5c515c] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.28646rem;
  height: 100%;
  border-left: 1px solid #cccccc7d;
  font-size: 0.07292rem;
}
.order_item .order_item_body .order_item_body_btn p[data-v-6f5c515c] {
  margin: 0.05208rem 0px;
}
.order_item .order_item_body .order_item_body_btn button[data-v-6f5c515c] {
  width: 0.78125rem;
  height: 0.26042rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}
.order_item .order_item_body .order_item_body_btn button[data-v-6f5c515c]:hover {
  background: #f7b203;
}
.order_item .order_item_body .order_item_body_btn .text_date[data-v-6f5c515c] {
  color: #FF0000;
}
.order_item .order_item_body .order_item_body_btn .text_res[data-v-6f5c515c] {
  color: #D5A223;
  cursor: pointer;
}
.bigredtext[data-v-6f5c515c] {
  font-size: 0.08333rem;
  font-weight: 800;
  color: #FF0000;
}
