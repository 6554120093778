.return_Goods_Box[data-v-1eb43db2] {
  width: 100%;
  min-height: 96vh;
  background: #3a3a47;
  padding: 0.3125rem 0.26042rem;
}
.return_Goods_Box .goodsinfo_box[data-v-1eb43db2] {
  background-color: #1D2533;
  border-radius: 0.20833rem;
  margin-bottom: 0.20833rem;
  display: flex;
  align-items: center;
  min-height: 2.70833rem;
  padding: 0.1875rem 0.20833rem;
}
.return_Goods_Box .goodsinfo_box .orderinfoimg[data-v-1eb43db2] {
  width: 2.8125rem;
  height: 2.34375rem;
  background: #fff;
  margin-right: 0.3125rem;
  border-radius: 0.15625rem;
  overflow: hidden;
}
.return_Goods_Box .goodsinfo_box .orderinfoimg img[data-v-1eb43db2] {
  width: 100%;
  height: 100%;
  object-fit: cove;
}
.return_Goods_Box .goodsinfo_box .orderinfo_Comtent[data-v-1eb43db2] {
  width: 50%;
}
.return_Goods_Box .goodsinfo_box .orderinfo_Comtent .orderinfo_Comtent_Title[data-v-1eb43db2] {
  font-size: 0.29167rem;
  font-weight: 800;
  color: #FFFFFF;
  margin-bottom: 0.26042rem;
}
.return_Goods_Box .goodsinfo_box .orderinfo_Comtent .orderinfo_gg[data-v-1eb43db2] {
  display: flex;
  flex-wrap: wrap;
}
.return_Goods_Box .goodsinfo_box .orderinfo_Comtent .orderinfo_gg span[data-v-1eb43db2] {
  padding: 0.05208rem 0.17708rem;
  background-color: #303A4B;
  margin-right: 0.15625rem;
  margin-top: 0.15625rem;
  border-radius: 0.07813rem;
  font-size: 0.22917rem;
  font-weight: 500;
  color: #999999;
}
.return_Goods_Box .goodsinfo_box .orderinfo_num_Price[data-v-1eb43db2] {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.return_Goods_Box .goodsinfo_box .orderinfo_num_Price .orderinfo_Price[data-v-1eb43db2] {
  font-size: 0.52083rem;
  font-weight: 800;
  color: #FF0021;
}
.return_Goods_Box .goodsinfo_box .orderinfo_num_Price .orderinfo_Price span[data-v-1eb43db2] {
  font-size: 0.22917rem;
}
.return_Goods_Box .goodsinfo_box .orderinfo_num_Price .orderinfo_num[data-v-1eb43db2] {
  font-size: 0.25rem;
  font-weight: 800;
  color: #fff;
}
.return_Goods_Box .Express_delivery_info[data-v-1eb43db2] {
  background-color: #1D2533;
  border-radius: 0.20833rem;
}
.return_Goods_Box .Express_delivery_info .Express_delivery_title[data-v-1eb43db2] {
  height: 1.04167rem;
  border-bottom: 1px solid #cccccc54;
  text-align: center;
  line-height: 1.04167rem;
  font-size: 0.25rem;
  font-weight: bold;
  color: #FFFFFF;
}
.return_Goods_Box .Express_delivery_info .contentBox[data-v-1eb43db2] {
  overflow: hidden;
}
.return_Goods_Box .Express_delivery_info .contentBox .content_row[data-v-1eb43db2] {
  display: flex;
  min-height: 1.5625rem;
  margin: 0.52083rem 0px;
}
.return_Goods_Box .Express_delivery_info .contentBox .content_row .content_item[data-v-1eb43db2] {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 0.3125rem;
  font-weight: bold;
  color: #FFFFFF;
  padding-left: 0.625rem;
}
.return_Goods_Box .Express_delivery_info .contentBox .content_row .content_item .content_title[data-v-1eb43db2] {
  color: #999999;
}
.return_Goods_Box .return_num[data-v-1eb43db2] {
  font-size: 0.35417rem;
  font-weight: 800;
  color: #FFFFFF;
}
.return_Goods_Box .return_num > div[data-v-1eb43db2]:nth-child(1) {
  margin: 0.3125rem 0px;
}
.return_Goods_Box .return_num .return_num_inp[data-v-1eb43db2] {
  height: 1.04167rem;
  background-color: #1D2533;
  border-radius: 0.20833rem;
}
.return_Goods_Box .return_num .return_num_inp input[data-v-1eb43db2] {
  width: 100%;
  height: 100%;
  text-indent: 0.3125rem;
  background-color: #00000000;
  border: none;
  outline: none;
  font-size: 0.25rem;
  font-weight: 800;
  color: #ffffff;
}
.return_Goods_Box .return_num .return_num_inp input[data-v-1eb43db2]::placeholder {
  font-size: 0.25rem;
  font-weight: 800;
  color: #666666;
}
.return_Goods_Box .submitBtn[data-v-1eb43db2] {
  margin: 0.52083rem auto;
  height: 0.91667rem;
  background: #F88503;
  border-radius: 0.45833rem;
  text-align: center;
  line-height: 0.91667rem;
  font-size: 0.3125rem;
  font-weight: 800;
  color: #FFFFFF;
}
