.specification[data-v-f9482652] {
  display: flex;
}
.specification .specification_name[data-v-f9482652] {
  font-size: 0.09375rem;
  font-weight: 400;
  color: #FFFFFF;
}
.specification .specification_list[data-v-f9482652] {
  width: 1.82292rem;
  display: flex;
  flex-wrap: wrap;
}
.specification .specification_list > div[data-v-f9482652] {
  height: 0.20833rem;
  margin-right: 0.06771rem;
  background: #3B4871;
  padding: 0px 0.07813rem;
  border-radius: 0.03125rem;
  text-align: center;
  line-height: 0.20833rem;
  font-size: 0.08333rem;
  font-weight: 800;
  color: #FFFFFF;
  margin-bottom: 0.0625rem;
  cursor: pointer;
}
.specification .specification_list .specification_listactive[data-v-f9482652] {
  background: #D8A931;
}
