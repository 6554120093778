/* @import url(); 引入css类 */
.purchase[data-v-54215c98] {
  width: 100%;
  height: 100vh;
  padding: 0.20833rem 0 0.20833rem 0.20833rem;
  background-color: #333c58;
  box-sizing: border-box;
}
.purchase .areaBoxs[data-v-54215c98] {
  position: fixed;
  left: 0.20833rem;
  top: 0.20833rem;
  width: 2.08333rem;
  padding-right: 0.10417rem;
  height: calc(100% - 0.20833rem);
  overflow-y: scroll;
  z-index: 1;
}
.purchase .areaBoxs[data-v-54215c98]::-webkit-scrollbar {
  width: 0.04167rem;
  height: 0.04167rem;
}
.purchase .areaBoxs[data-v-54215c98]::-webkit-scrollbar-track {
  background-color: rgba(192, 169, 22, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.purchase .areaBoxs[data-v-54215c98]::-webkit-scrollbar-thumb {
  background-color: #939399;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.purchase .landList[data-v-54215c98] {
  width: calc(100% - 0.10417rem);
}
.purchase .landList .items[data-v-54215c98] {
  position: relative;
  width: 100%;
  margin-bottom: 0.10417rem;
  border-radius: 0.05208rem;
  box-sizing: border-box;
  cursor: pointer;
}
.purchase .landList .items.cur[data-v-54215c98] {
  border: solid 2px #d5a223;
}
.purchase .landList .items.cur[data-v-54215c98]::before {
  content: "";
  display: block;
  position: absolute;
  right: -0.20833rem;
  top: 50%;
  width: 0.09896rem;
  height: 0.08854rem;
  margin-top: -0.04688rem;
  background: url(https://ipqncdn1.artuns.com/5ada4ef1341dc39b02a34a88ea0a4af1_arrow.png) no-repeat center;
  background-size: 0.09896rem 0.08854rem;
  z-index: 2;
}
.purchase .landList .items img.bg[data-v-54215c98] {
  display: block;
  width: 100%;
  border-radius: 0.05208rem;
}
.purchase .landList .items .name[data-v-54215c98] {
  position: absolute;
  left: 0.07813rem;
  bottom: 0.07813rem;
  font-size: 0.10417rem;
  font-weight: 800;
  color: #ffffff;
  z-index: 2;
}
.purchase .landList .items .sellStatus[data-v-54215c98] {
  position: absolute;
  right: 0.07813rem;
  bottom: 0.07813rem;
  display: flex;
  align-items: center;
  font-size: 0.07292rem;
  color: #ffffff;
  z-index: 2;
}
.purchase .pullStatus[data-v-54215c98] {
  padding-right: 0.10417rem;
  padding-bottom: 0.10417rem;
}
.purchase .pullStatus .drawer-footer span[data-v-54215c98] {
  color: #fff;
}
.purchase .details[data-v-54215c98] {
  padding-left: 2.1875rem;
  padding-top: 0.41667rem;
  height: calc(100vh - 0.41667rem);
}
.purchase .details .tablist[data-v-54215c98] {
  position: fixed;
  top: 0.05208rem;
  display: flex;
  width: calc(100% - 2.85417rem);
  border-bottom: 1px solid #ffffff20;
  z-index: 99;
}
.purchase .details .tablist .items[data-v-54215c98] {
  min-width: 0.9375rem;
  font-size: 0.125rem;
  font-weight: 700;
  text-align: center;
  color: #ccc;
  line-height: 0.41667rem;
  cursor: pointer;
}
.purchase .details .tablist .items.cur[data-v-54215c98] {
  position: relative;
  color: #D6A324;
}
.purchase .details .tablist .items.cur[data-v-54215c98]::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(50% - 0.26042rem);
  width: 0.52083rem;
  height: 2px;
  background: #D6A324;
}
.purchase .details .landLists[data-v-54215c98] {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 0.20833rem;
  padding-left: 0.20833rem;
  padding-bottom: 0.41667rem;
  background: linear-gradient(180deg, #333c58 0%, #10161b 100%);
  overflow-y: scroll ;
}
.purchase .details .landLists[data-v-54215c98]::-webkit-scrollbar {
  width: 0.04167rem;
  height: 0.04167rem;
}
.purchase .details .landLists[data-v-54215c98]::-webkit-scrollbar-track {
  background-color: rgba(192, 169, 22, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.purchase .details .landLists[data-v-54215c98]::-webkit-scrollbar-thumb {
  background-color: #939399;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
.purchase .details .landLists .noMore[data-v-54215c98] {
  flex: 0 0 100%;
  color: #ccc;
  text-align: center;
}
.purchase .details .landLists .items[data-v-54215c98] {
  position: relative;
  float: left;
  display: flex;
  justify-content: space-between;
  width: calc(50% - 0.20833rem);
  padding: 0.13021rem 0.15625rem;
  margin-right: 0.20833rem;
  margin-bottom: 0.15625rem;
  background: url(https://ipqncdn1.artuns.com/344bc29ccce11bee851310e044b334ca_158@2x.png) no-repeat center;
  background-size: cover;
  border-radius: 0.07292rem;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}
.purchase .details .landLists .items.cur[data-v-54215c98] {
  background-image: url(https://ipqncdn1.artuns.com/98417f76cf38cd7aa46784cc1ab30df5_159@2x.png);
}
.purchase .details .landLists .items .ft .numberPlate[data-v-54215c98] {
  font-size: 0.14583rem;
  font-weight: 800;
  color: #ffffff;
}
.purchase .details .landLists .items .ft .areaInfo[data-v-54215c98] {
  font-size: 0.10417rem;
  color: #eeeeee;
  margin-top: 0.15625rem;
}
.purchase .details .landLists .items .rt[data-v-54215c98] {
  padding-top: 0.23438rem;
}
.purchase .details .landLists .items .rt .price[data-v-54215c98] {
  display: flex;
  align-items: baseline;
  font-size: 0.10417rem;
  font-weight: bold;
  color: #0436b5;
}
.purchase .details .landLists .items .rt .price .num[data-v-54215c98] {
  font-size: 0.20833rem;
}
.purchase .details .landLists .items.isSold[data-v-54215c98] {
  background-image: none;
  background-color: #343434;
}
.purchase .details .landLists .items.isSold .ft .numberPlate[data-v-54215c98],
.purchase .details .landLists .items.isSold .ft .areaInfo[data-v-54215c98] {
  color: #999999;
}
.purchase .details .landLists .items.isSold .rt[data-v-54215c98] {
  padding-top: 0;
  text-align: center;
}
.purchase .details .landLists .items.isSold .rt .imgs[data-v-54215c98] {
  width: 0.26042rem;
  height: 0.26042rem;
  margin: 0 auto 0.05208rem;
  border-radius: 100%;
  overflow: hidden;
}
.purchase .details .landLists .items.isSold .rt .imgs img[data-v-54215c98] {
  display: block;
  width: 0.26042rem;
  height: 0.26042rem;
}
.purchase .details .landLists .items.isSold .rt .status[data-v-54215c98] {
  font-size: 0.125rem;
  font-weight: 800;
  color: #ffffff;
}
.purchase .details .landLists[data-v-54215c98]::after {
  content: "";
  display: block;
  clear: both;
}
.purchase .buyBox[data-v-54215c98] {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2.39583rem);
  height: 0.625rem;
  padding: 0.15625rem 0.23438rem;
  background: linear-gradient(180deg, #333c58 0%, #1b2731 100%);
  border-radius: 0px 0px 0px 0px;
  z-index: 1;
}
.purchase .buyBox .priceInfo[data-v-54215c98] {
  display: flex;
  align-items: baseline;
  font-size: 0.13542rem;
  font-weight: 800;
  color: #ffffff;
}
.purchase .buyBox .priceInfo .price[data-v-54215c98] {
  display: flex;
  align-items: baseline;
  font-size: 0.10417rem;
  color: #d6a324;
}
.purchase .buyBox .priceInfo .price .num[data-v-54215c98] {
  font-size: 0.19792rem;
}
.purchase .buyBox .btns[data-v-54215c98] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.purchase .buyBox .btns .viewGround[data-v-54215c98],
.purchase .buyBox .btns .buyLand[data-v-54215c98] {
  width: 0.83333rem;
  height: 0.3125rem;
  line-height: 0.3125rem;
  text-align: center;
  border-radius: 0.15625rem;
  font-size: 0.125rem;
  font-weight: 800;
  color: #ffffff;
  cursor: pointer;
}
.purchase .buyBox .btns .viewGround[data-v-54215c98] {
  background: linear-gradient(109deg, #00cbff 0%, #07bde6 60%, #0bb3d6 100%);
}
.purchase .buyBox .btns .viewGround.disable[data-v-54215c98] {
  background: #958787;
}
.purchase .buyBox .btns .buyLand[data-v-54215c98] {
  margin-left: 0.15625rem;
  background: linear-gradient(109deg, #d5a223 0%, #ffd263 100%);
}
.purchase .buyBox .btns .buyLand.disable[data-v-54215c98] {
  background: #958787;
}
.purchase[data-v-54215c98] .el-dialog .el-dialog__body {
  padding-top: 0.02604rem;
}
.purchase[data-v-54215c98] .el-dialog .el-dialog__body .code {
  display: block;
  margin: 0 auto;
}
.purchase[data-v-54215c98] .el-dialog .el-dialog__body .tips {
  text-align: center;
  margin-top: 0.05208rem;
}
