img[data-v-7e0d7760] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.goodsbox .goodsimg[data-v-7e0d7760] {
  width: 100%;
  height: 6.19792rem;
  background: #fff;
}
.goodsbox .goodstitle[data-v-7e0d7760] {
  min-height: 1.92708rem;
  padding: 0.10417rem 0.26042rem;
}
.goodsbox .goodstitle .goodsPriceNum[data-v-7e0d7760] {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.goodsbox .goodstitle .goodsPriceNum .goodsPrice[data-v-7e0d7760] {
  font-size: 0.625rem;
  font-weight: 800;
  color: #FF0000;
}
.goodsbox .goodstitle .goodsPriceNum .goodsPrice i[data-v-7e0d7760] {
  font-size: 0.25rem;
  font-style: normal;
}
.goodsbox .goodstitle .goodsPriceNum .goodsPrice span[data-v-7e0d7760] {
  font-size: 0.25rem;
  font-weight: 800;
  color: #999999;
  text-decoration: line-through;
}
.goodsbox .goodstitle .goodsPriceNum .goodsNum[data-v-7e0d7760] {
  font-size: 0.25rem;
  font-weight: 800;
  color: #999999;
}
.goodsbox .goodstitle .goodsname[data-v-7e0d7760] {
  font-size: 0.3125rem;
  font-weight: 800;
  color: #FFFFFF;
}
.goodsbox .goodsSkuAddress[data-v-7e0d7760] {
  padding: 0.3125rem 0.26042rem;
  background-color: #363E4B;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox[data-v-7e0d7760] {
  border-radius: 0.20833rem;
  background-color: #1D2533;
  padding: 0.10417rem 0px;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem[data-v-7e0d7760] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.04167rem;
  padding: 0px 0.3125rem;
  font-size: 0.25rem;
  font-weight: 800;
  color: #666666;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem > div[data-v-7e0d7760] {
  display: flex;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem > div .skutitle[data-v-7e0d7760] {
  margin-right: 0.3125rem;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem > div .skuname[data-v-7e0d7760] {
  width: 4.6875rem;
  color: #fff;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem > div .skuname p[data-v-7e0d7760] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goodsbox .goodsSkuAddress .goodsSkuAddressBox .skuitem > div .skuname span[data-v-7e0d7760] {
  margin-right: 0.10417rem;
}
.goodsbox .goodscontent[data-v-7e0d7760] {
  color: #fff;
}
.goodsbox .goodscontent .goodscontentTitle[data-v-7e0d7760] {
  height: 1.04167rem;
  background: #1D2533;
  font-size: 0.3125rem;
  font-weight: 800;
  color: #fff;
  text-align: center;
  line-height: 1.04167rem;
}
.goodsbox .goodscontent .goodscontentView[data-v-7e0d7760] {
  padding: 0px 0.26042rem;
}
.goodsbox .Buybtn[data-v-7e0d7760] {
  width: 80%;
  height: 0.91667rem;
  margin: 0.26042rem auto;
  background: #F88503;
  border-radius: 0.45833rem;
  font-size: 0.3125rem;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.91667rem;
}
.popupBox[data-v-7e0d7760] {
  position: fixed;
  top: 0.78125rem;
  left: 0px;
  width: 100%;
  height: 95vh;
  background-color: #00000058;
}
.popupBox .popSkubox[data-v-7e0d7760] {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  min-height: 5.20833rem;
  background: #3A3A47;
  border-radius: 0.41667rem 0.41667rem 0px 0px;
}
.popupBox .popSkubox .popSkuboxHead[data-v-7e0d7760] {
  display: flex;
  align-items: center;
  padding: 0.26042rem;
  border-bottom: 1px solid #cccccc28;
}
.popupBox .popSkubox .popSkuboxHead .goodsSkuimg[data-v-7e0d7760] {
  width: 1.875rem;
  height: 1.5625rem;
  border-radius: 0.20833rem;
  margin-right: 0.36458rem;
}
.popupBox .popSkubox .popSkuboxHead .popSkunum[data-v-7e0d7760] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.45833rem;
  font-size: 0.27083rem;
  font-weight: 800;
  color: #999999;
}
.popupBox .popSkubox .popSkuboxHead .popSkunum .popPrice[data-v-7e0d7760] {
  font-size: 0.52083rem;
  font-weight: 800;
  color: #FF0000;
}
.popupBox .popSkubox .popSkuboxHead .popSkunum i[data-v-7e0d7760] {
  font-size: 0.27083rem;
  font-style: normal;
}
.popupBox .popSkubox .popSkuboxHead .popSkunum span[data-v-7e0d7760] {
  font-size: 0.27083rem;
  font-weight: 800;
  color: #666666;
  text-decoration: line-through;
}
.popupBox .popSkubox .skubox[data-v-7e0d7760] {
  padding: 0.125rem 0.26042rem;
}
.popupBox .popSkubox .skubox .skuitem .skuitemtitel[data-v-7e0d7760] {
  margin: 0.25rem 0px;
  font-size: 0.3125rem;
  font-weight: 800;
  color: #FFFFFF;
}
.popupBox .popSkubox .skubox .skuitem .skuitemobj[data-v-7e0d7760] {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.3125rem;
}
.popupBox .popSkubox .skubox .skuitem .skuitemobj > div[data-v-7e0d7760] {
  width: 2.10417rem;
  height: 0.72917rem;
  background: #585858;
  border-radius: 0.36458rem;
  text-align: center;
  line-height: 0.72917rem;
  font-size: 0.27083rem;
  font-weight: 800;
  color: #FFFFFF;
}
.popupBox .popSkubox .skubox .skuitem .skuitemobj .activeitem[data-v-7e0d7760] {
  background-color: #F88503;
}
.popupBox .popSkubox .num_box_title[data-v-7e0d7760] {
  width: 80%;
  margin: 0.52083rem auto;
  font-size: 0.41667rem;
  font-weight: 800;
  color: #fff;
}
.popupBox .popSkubox .num_box[data-v-7e0d7760] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 1.04167rem;
  border-radius: 0.52083rem;
  background-color: #cccccc;
  margin: 0.52083rem auto;
}
.popupBox .popSkubox .num_box > div[data-v-7e0d7760] {
  flex: 1;
  text-align: center;
  font-size: 0.41667rem;
  font-weight: 800;
}
.popupBox .popSkubox .num_box > div[data-v-7e0d7760]:nth-child(2) {
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  color: #F88503;
}
.popupBox .popSkubox .skubtn[data-v-7e0d7760] {
  width: 80%;
  height: 0.91667rem;
  background: #F88503;
  border-radius: 0.45833rem;
  font-size: 0.3125rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.91667rem;
  margin: 0.20833rem auto 0px;
}
