.popup_box[data-v-6308de00] {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: #00000066;
  z-index: 9999;
}
.popup_box .select_box[data-v-6308de00] {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 6.25rem;
  background-color: #f5f5f5;
  border-radius: 0.41667rem 0.41667rem 0px 0px;
}
.popup_box .select_box .select_box_head[data-v-6308de00] {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 1.04167rem;
  padding: 0px 0.36458rem;
  font-size: 0.25rem;
  font-weight: 800;
  line-height: 1.04167rem;
  border-bottom: 1px solid #cccccc94;
}
.popup_box .select_box .select_box_head > div[data-v-6308de00] {
  margin-right: 0.26042rem;
  height: 1.04167rem;
}
.popup_box .select_box .select_box_head .isactive[data-v-6308de00] {
  position: relative;
  color: #F88503;
}
.popup_box .select_box .select_box_head .isactive[data-v-6308de00]::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0.78125rem;
  height: 0.05208rem;
  background-color: #F88503;
}
.popup_box .select_box .select_box_head .clearbtn[data-v-6308de00] {
  position: absolute;
  right: 0.26042rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.9375rem;
  height: 0.52083rem;
  background-color: #F88503;
  border-radius: 0.10417rem;
  text-align: center;
  line-height: 0.52083rem;
  color: #fff;
}
.popup_box .select_box .citybox[data-v-6308de00] {
  width: 100%;
  height: 5.20833rem;
  overflow-y: auto;
}
.popup_box .select_box .citybox .city_item[data-v-6308de00] {
  height: 0.78125rem;
  padding: 0px 0.41667rem;
  line-height: 0.78125rem;
  font-size: 0.25rem;
  font-weight: 800;
  color: #333;
}
.popup_box .select_box .citybox .activecity_item[data-v-6308de00] {
  background: #f8860361;
}
