.navbartitle[data-v-5c649b5f] {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0.78125rem;
  padding: 0px 0.26042rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #3A3A47;
  font-size: 0.35417rem;
  font-weight: 800;
  color: #fff;
  z-index: 999;
}
.perch[data-v-5c649b5f] {
  width: 100%;
  height: 0.78125rem;
  background-color: #363E4B;
}
.navbarBox[data-v-5c649b5f] {
  position: fixed;
  bottom: 0px;
  display: flex;
  width: 100%;
  height: 1.04167rem;
  background: #3A3A47;
}
.navbarBox .navbaiItem[data-v-5c649b5f] {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.20833rem;
  font-weight: bold;
  color: #999999;
}
.navbarBox .navbaiItem img[data-v-5c649b5f] {
  height: 0.33854rem;
  margin-bottom: 0.125rem;
}
.navbarBox .activeItem[data-v-5c649b5f] {
  color: #F88503;
}
