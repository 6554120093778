.my-container[data-v-700c5798] {
  padding: 0.3125rem 0 0.26042rem;
  background: #161730;
}
.my-container .w1300[data-v-700c5798] {
  display: flex;
  width: 6.77083rem;
  margin: 0 auto;
}
.layout-wrap[data-v-700c5798] {
  margin-right: 0.20833rem;
}
.layout-wrap .user-box[data-v-700c5798] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.5625rem;
  height: 1.54167rem;
  margin-bottom: 0.26042rem;
  background: linear-gradient(264deg, #56174A 0%, #272E6B 100%);
  border-radius: 0.06771rem;
}
.layout-wrap .user-box .head-img[data-v-700c5798] {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  margin-bottom: 0.14583rem;
}
.layout-wrap .user-box .name[data-v-700c5798] {
  margin-bottom: 0.125rem;
  font-size: 0.11458rem;
  font-weight: 700;
  color: #fff;
}
.layout-wrap .user-box .num[data-v-700c5798] {
  font-size: 0.08333rem;
  color: #999;
}
.layout-wrap .menu-tabs[data-v-700c5798] {
  display: flex;
  flex-direction: column;
  width: 1.5625rem;
  height: 1.25rem;
  background: #00000030;
  border-radius: 0.06771rem;
}
.layout-wrap .menu-tabs .item[data-v-700c5798] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.41667rem;
  padding: 0 0.15625rem;
  color: #fff;
  font-size: 0.10417rem;
  font-weight: 700;
  border-bottom: 1px solid #ffffff10;
  cursor: pointer;
}
.layout-wrap .menu-tabs .item.router-link-active[data-v-700c5798] {
  color: #EC5C56;
}
.layout-wrap .menu-tabs .item[data-v-700c5798]:last-child {
  border-color: transparent ;
}
.layout-wrap .menu-tabs .item .el-icon-arrow-right[data-v-700c5798] {
  font-size: 0.09375rem;
  opacity: 0.6;
}
