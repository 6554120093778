.wrap[data-v-7f85f7e8] {
  display: flex;
  width: 100%;
  height: 100%;
  background: #1d2533;
}
.wrap .wrap-left[data-v-7f85f7e8] {
  min-height: 100vh;
  background: #333c58;
  position: fixed;
  left: 0;
  width: 1.25rem;
}
.wrap .wrap-left .user_center[data-v-7f85f7e8] {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 0.33854rem;
  text-align: center;
  line-height: 0.33854rem;
  background: #2d2d35;
  color: #d5a223;
  font-size: 0.13542rem;
  font-weight: 700;
  cursor: pointer;
}
.wrap .wrap-right[data-v-7f85f7e8] {
  flex: initial;
  padding: 0.20833rem 0.20833rem 0.20833rem 1.45833rem;
}
.wrap[data-v-7f85f7e8] .head-banner {
  position: relative;
  width: 100%;
  height: 2.08333rem;
  margin-bottom: 0.20833rem;
  background: #1d2c44;
  overflow: hidden;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper {
  width: 100%;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item {
  position: relative;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .imgs {
  width: 100%;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .imgs img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 2.08333rem;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .texts {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6.77083rem;
  height: 1.66667rem;
  margin-top: -0.83333rem;
  margin-left: -3.38542rem;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .texts .tag {
  font-size: 0.10417rem;
  font-weight: 400;
  color: #c4c4c4;
  line-height: 0.14583rem;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .texts .banner-title {
  font-size: 0.26042rem;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0.20833rem;
  margin-bottom: 0.72917rem;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .item .texts .view-more {
  display: block;
  width: 0.78125rem;
  height: 0.26042rem;
  line-height: 0.26042rem;
  background: #ec5c56;
  border-radius: 0.02083rem;
  font-size: 0.10417rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .swiper-pagination {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 0.10417rem 0;
  justify-content: center;
  z-index: 99;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .swiper-pagination .swiper-pagination-bullet {
  display: flex;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: 0 0.07813rem;
  background-color: #f00;
  border-radius: 0.0625rem;
  z-index: 99;
  box-shadow: 1px 1px 0.01563rem #666;
}
.wrap[data-v-7f85f7e8] .head-banner .swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}
.wrap-left[data-v-7f85f7e8] {
  min-height: 100vh;
  flex: 0 0 1.30208rem;
  background: #333c58;
}
.wrap-left .item[data-v-7f85f7e8] {
  height: 0.52083rem;
  line-height: 0.52083rem;
  text-align: center;
  background: #2d2d35;
  color: #fff;
  font-size: 0.13542rem;
  font-weight: 700;
  border-bottom: 1px solid #494a52;
  cursor: pointer;
}
.wrap-left .item.active[data-v-7f85f7e8] {
  background: #d5a223;
}
.wrap-right[data-v-7f85f7e8] {
  flex: 0 0 calc(100% - 1.30208rem);
  padding: 0.20833rem 0.28646rem;
}
.wrap-right .tabs[data-v-7f85f7e8] {
  display: flex;
  margin-bottom: 0.15625rem;
}
.wrap-right .tabs .tab[data-v-7f85f7e8] {
  display: flex;
  flex-direction: column;
  line-height: 0.20833rem;
  margin: 0 0.15625rem 0 0;
  color: #fff;
  font-weight: 700;
  font-size: 0.10417rem;
  cursor: pointer;
}
.wrap-right .tabs .tab.active[data-v-7f85f7e8] {
  color: #d5a223;
  border-bottom: 2px solid #d5a223;
}
.wrap-right .list[data-v-7f85f7e8] {
  display: flex;
  flex-wrap: wrap;
}
.wrap-right .list .item[data-v-7f85f7e8] {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(25% - 0.13021rem);
  margin-right: 0.15625rem;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.wrap-right .list .item[data-v-7f85f7e8]:nth-child(4n) {
  margin-right: 0;
}
.wrap-right .list .item .img[data-v-7f85f7e8] {
  width: 100%;
  height: 1.25rem;
  margin-bottom: 0.05208rem;
}
.wrap-right .list .item .info[data-v-7f85f7e8] {
  padding: 0.05208rem 0 0.15625rem;
}
.wrap-right .list .item .info .title[data-v-7f85f7e8] {
  margin-bottom: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: 700;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}
.wrap-right .list .item .goods-footer[data-v-7f85f7e8] {
  display: flex;
  align-items: center;
  width: 100%;
}
.wrap-right .list .item .goods-footer .price-new[data-v-7f85f7e8] {
  margin-right: 0.05208rem;
  font-size: 0.10417rem;
  color: #ff0000;
  font-weight: 700;
}
.wrap-right .list .item .goods-footer .price-new i[data-v-7f85f7e8] {
  font-size: 0.0625rem;
  font-style: initial;
}
.wrap-right .list .item .goods-footer .price-old[data-v-7f85f7e8] {
  color: #959595;
  font-size: 0.07292rem;
  text-decoration: line-through;
}
.wrap-right .list .item .goods-footer .name[data-v-7f85f7e8] {
  font-size: 0.07292rem;
  color: #d5a223;
  margin-left: auto;
}
.wrap-right[data-v-7f85f7e8] .el-empty {
  margin-top: 0.625rem;
}
.wrap-right[data-v-7f85f7e8] .paging {
  width: 100%;
  margin-top: 0.10417rem;
}
.wrap-right[data-v-7f85f7e8] .paging .el-pagination {
  text-align: center;
}
.wrap-right[data-v-7f85f7e8] .paging .el-pager li {
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
  background-color: white;
}
.wrap-right[data-v-7f85f7e8] .paging .el-pager li:not(.disabled).active {
  background-color: #d71a18;
  color: #fff;
}
.wrap-right[data-v-7f85f7e8] .paging .btn-next,
.wrap-right[data-v-7f85f7e8] .paging .btn-prev {
  background-color: white;
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
}
