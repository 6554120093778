.goodsView[data-v-41647900] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.goodsView .slidePrev[data-v-41647900],
.goodsView .slideNext[data-v-41647900] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.38542rem;
  height: 3.02083rem;
  background: #55738B63;
}
.goodsView .slidePrev[data-v-41647900] {
  border-top-left-radius: 0.15625rem;
  border-bottom-left-radius: 0.15625rem;
}
.goodsView .slideNext[data-v-41647900] {
  border-top-right-radius: 0.15625rem;
  border-bottom-right-radius: 0.15625rem;
}
.goodsView .slidePrev i[data-v-41647900],
.goodsView .slideNext i[data-v-41647900] {
  display: block;
  width: 0.09375rem;
  height: 0.11979rem;
}
.goodsView .slidePrev i[data-v-41647900] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAvCAYAAABkBQNlAAAAAXNSR0IArs4c6QAAA79JREFUWEfFWWdvFTEQnKH3jqiiiCYQCPH/fwRfgkKANAIJSheENCCL5sUXGXP2ee+FsF+fzx7v7szu+hH/wMzsDIBbAE4C+ApghuSP2qNYu7B2nZldBnAvWb8NYISk1eyzr6DMTN65njl4jOTagYEyM13uPoCLhUPlqa0DAWVmRwA8BHC6cOAyyckaQFozVPjM7DiAxwCOFQ5cATBZm09DgQoMk4cOFwDNkpyr9VCzrpenzEy5oxzKfb8DYIqkvOQ2NygzuwHgZuGknwA+kPzuRhM+qAYVGCb9uVQ4bCMAki65zMwuKBVILlWBMjPljfJHSp2zbwGQQucyM7sN4Fr4qBuUmYlZYpiYlrMFkh9dSACEywrQlejb9aKnzEzaIw9Ji9pMZeMTyfmegHTZU8m341lQIcZi2KHMgb8ATJBUwXWZmalQP0i8r/1UijZaQZmZ6pcYlgOtRH5PctOFZjdkyksBir2/DmCc5IAgfxwaGHYXgCp9zkR1UV7Ud1mmgxBBBEieGtgeKDNTmJQ/ZwsnLQdRrGpB4n0ShjU/LZGcSs8bgAqAHnVQfo7krMs1eYZpGyn+Utt+Dag7AK5mDuxdMgLlU4YpTAK0mrsgAxOeZRb0LhkB0NOEYeqnlD9S/qwJlBI7Fq9msZglhrlLRkgJAYo1SAx7Fyd0yVPPW9RaQEb7MCwAUh0T7fcSOgwPewzr8tSrFoGcJznjTepmfdJJrJMc9eyl8L1sKSNrJMc8GyX0j9ubLZIjnr0EKo19871mNXdNy4RvMdTI6vCpnOhmbfaZ5BfPLaMQprnqSvSjAF4U6lyv/ApS8yTp4auANeJZ8pYu7xqRIm+1dQOdWhXXPt2q1FmqRZHwuTrLjKpLlKVZrSIag6ppedUhSFCrEjbymPZOy42ATbeVm7bWRaJ3vpDcUnrdsvoVJQKnwSNti/4qzLkmL1d6mv2HafI6gZXa4Zr5TqEUo1yWmR0XSU5ro67BQa6W13LrhunT296xBsA65z4zOxeKa26AUBeqBwz3iJ5pj2c7QYWyoVFLnWnuMUPAVJYWXHHc7UylZbHIrlaBCsA0jOpjVYCc9W2ZBax5n6jzVERpAZLenCgA6zUtx/tVeyoRQoWy9HLnfigbClQIpS7TJbK9HzzcnopvZGZtQhgvkYZJy1yD61Cggte6OgyVpbeeejk0qABM05BENmcrJCdq5WJfQAVg6QQTY9gh+frAQXWI7CbJN/8FVAAmDdNDSfPyp/qoV5qqv0A6C3LtzdrWhXco/bTtnbJ/A2emmsuZvMpJAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
}
.goodsView .slideNext i[data-v-41647900] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAvCAYAAABkBQNlAAAAAXNSR0IArs4c6QAAA79JREFUWEfFWWdvFTEQnKH3jqiiiCYQCPH/fwRfgkKANAIJSheENCCL5sUXGXP2ee+FsF+fzx7v7szu+hH/wMzsDIBbAE4C+ApghuSP2qNYu7B2nZldBnAvWb8NYISk1eyzr6DMTN65njl4jOTagYEyM13uPoCLhUPlqa0DAWVmRwA8BHC6cOAyyckaQFozVPjM7DiAxwCOFQ5cATBZm09DgQoMk4cOFwDNkpyr9VCzrpenzEy5oxzKfb8DYIqkvOQ2NygzuwHgZuGknwA+kPzuRhM+qAYVGCb9uVQ4bCMAki65zMwuKBVILlWBMjPljfJHSp2zbwGQQucyM7sN4Fr4qBuUmYlZYpiYlrMFkh9dSACEywrQlejb9aKnzEzaIw9Ji9pMZeMTyfmegHTZU8m341lQIcZi2KHMgb8ATJBUwXWZmalQP0i8r/1UijZaQZmZ6pcYlgOtRH5PctOFZjdkyksBir2/DmCc5IAgfxwaGHYXgCp9zkR1UV7Ud1mmgxBBBEieGtgeKDNTmJQ/ZwsnLQdRrGpB4n0ShjU/LZGcSs8bgAqAHnVQfo7krMs1eYZpGyn+Utt+Dag7AK5mDuxdMgLlU4YpTAK0mrsgAxOeZRb0LhkB0NOEYeqnlD9S/qwJlBI7Fq9msZglhrlLRkgJAYo1SAx7Fyd0yVPPW9RaQEb7MCwAUh0T7fcSOgwPewzr8tSrFoGcJznjTepmfdJJrJMc9eyl8L1sKSNrJMc8GyX0j9ubLZIjnr0EKo19871mNXdNy4RvMdTI6vCpnOhmbfaZ5BfPLaMQprnqSvSjAF4U6lyv/ApS8yTp4auANeJZ8pYu7xqRIm+1dQOdWhXXPt2q1FmqRZHwuTrLjKpLlKVZrSIag6ppedUhSFCrEjbymPZOy42ATbeVm7bWRaJ3vpDcUnrdsvoVJQKnwSNti/4qzLkmL1d6mv2HafI6gZXa4Zr5TqEUo1yWmR0XSU5ro67BQa6W13LrhunT296xBsA65z4zOxeKa26AUBeqBwz3iJ5pj2c7QYWyoVFLnWnuMUPAVJYWXHHc7UylZbHIrlaBCsA0jOpjVYCc9W2ZBax5n6jzVERpAZLenCgA6zUtx/tVeyoRQoWy9HLnfigbClQIpS7TJbK9HzzcnopvZGZtQhgvkYZJy1yD61Cggte6OgyVpbeeejk0qABM05BENmcrJCdq5WJfQAVg6QQTY9gh+frAQXWI7CbJN/8FVAAmDdNDSfPyp/qoV5qqv0A6C3LtzdrWhXco/bTtnbJ/A2emmsuZvMpJAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
  transform: rotate(180deg);
}
.goodsView .goodsSwiperView[data-v-41647900] {
  width: 6.5625rem;
  height: 3.54167rem;
  border-radius: 0.15625rem;
  border: 0.01563rem solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
.goodsView .goodsSwiperView .swiper[data-v-41647900] {
  width: 100%;
  height: 100%;
}
.goodsView .goodsSwiperView .swiper .item[data-v-41647900] {
  width: 100%;
  height: 100%;
}
.asd[data-v-41647900] {
  text-align: center;
  font-size: 0.41667rem;
  font-weight: 900;
  color: red;
}
