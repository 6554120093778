.w[data-v-0300e976] {
  width: 8.4375rem;
  margin: auto;
  margin-top: 0.26042rem;
}
.bigbox[data-v-0300e976] {
  display: flex;
  justify-content: space-between;
}
.menu[data-v-0300e976] {
  width: 1.5625rem;
}
.menu ul[data-v-0300e976] {
  font-size: 0.09375rem;
  font-weight: 800;
  color: #E4E4E4;
}
.menu ul li[data-v-0300e976] {
  position: relative;
  height: 0.41667rem;
  line-height: 0.41667rem;
  background-color: #2B2B2B;
  border-bottom: 1px solid #cccccc3f;
  padding-left: 0.15625rem;
  cursor: pointer;
}
.menu ul li a[data-v-0300e976] {
  color: #fff;
}
.menu ul li i[data-v-0300e976] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.15625rem;
  width: 0.04167rem;
  height: 0.07813rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAcZJREFUSEvFlb91wjAQxu9U2GWSCZINwgaBDWCC4AbJVcgEIRMEKiMawwZsEEYgG2QEUkKhyzue7SeE5D9posrPT/rp7r5Pd6i1HhLRBwCAEGI0mUz2/N11odb6AAA35UFjTJKm6fovoG8AuHcOzqSU711guFqtekS0s6NiABGtlVJJWxjyxizLHhBxi4iPzsF9FEWDJEk4/dp1BvHK8/z2dDptAeDJhSFi0iRCBSoPa6250M8O7ICIgzrYFahIdSyEyF2YMeY1pKgXxAD2FwBwdJU1ChGmSqmFW7AgiDcWinLdLuzhU7QWVIpwPB53rqJsmTiOR6WijSBL0blHhMoerUCWojMAeLPrQ0QLpdS0Eyig6EFKedcZxLDlcjlHxBfroQ/+D5Rl2ZVZpZTYKSKt9RQAzk3QWhsp5bg1yPcGiegrjuM+e6kVqAnC0dWCitbyCQA9xztVJOX/ICgEAYBNFEVTt9l5QcVj5TZyEQlDuLC+VnkFKiCczq3vKYT67QUoBGkzoiqQz2h8extIpVoA8mOMGaZpyqOqcWEIgoj9pslh03lk843VCGK3CiHGXSDn1OyWYFu+MRdnQzlp+/y/bT18l/wCT6/6Kkf5EygAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100% 100%;
}
.menu ul li[data-v-0300e976]:last-child {
  border-bottom: 0px;
}
.menu ul .activeItem[data-v-0300e976] {
  color: #D5A223;
}
.menu ul .activeItem i[data-v-0300e976] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAe1JREFUSEvFlU1S20AQhfuNvIoXISdAkVeJVYVvgH0DWKew8AninCDkBMAJbJli7xugI0CVgZUVH8FZJCs0nWqBHP2MrNEqvdFPab5p9XvTjaeb3glrviQi4o4+9b9s7uW+bWAVelsA77OFTDTxx+t5a9DjwtsQ4bCwEHzRP4t/tIFhdesO6EVF+axeATzvj+OJLQzy4fPMdRNHLUE4Kixkvn+n9ejjZLNtAqYgiZ8z9+C34yxBdFyGcUdPmkTYgbLFq7A3ByjIw5h5Sx092gergASwWvTOQTSrwIBvdYoaQQIQf2nN87IIYJ5+DuLrcs1qQWlmt+4AiVpW7GFQdC/onwgqKivKRFE3SU4zRRtBO5hyrsoiUM4eVqCsHo+hd0HA95II134QT1uBTIqKNfwg/tAalMJC7wrA1ywzhWT0/0Ams/bHa7TK6GnhTZmQNsEsmCn0g7WcBLswnkHih26ih+IlK1ATRFLZC5LW8kepOwIGhd/JZZK9rwXVQpjCrk6m5WZnbiNyWF/UrJLJW2FNVa02ttcefgfgwHQU6qQpgN4GQRViMaJ2IJPRZHfbOZeCalrrL0fpk09nm8jGaaiDUEcPmyZHfgMBRfkRxMQP5OjzNpDUkPmWIJDM8ja/U8hIHp5v3KFcbeth2uQvuRQC46HEVhgAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100% 100%;
}
.menu ul .activeItem a[data-v-0300e976] {
  color: #D5A223;
}
.menu ul .activeItem[data-v-0300e976]::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  width: 0.01563rem;
  height: 0.13542rem;
  background: #D5A223;
}
.body_main[data-v-0300e976] {
  width: 6.66667rem;
}
