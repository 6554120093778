.order_list[data-v-630069ba] .paging {
  width: 100%;
  margin-top: 0.10417rem;
}
.order_list[data-v-630069ba] .paging .el-pagination {
  text-align: center;
}
.order_list[data-v-630069ba] .paging .el-pager li {
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
  background-color: white;
}
.order_list[data-v-630069ba] .paging .el-pager li:not(.disabled).active {
  background-color: #d71a18;
  color: #fff;
}
.order_list[data-v-630069ba] .paging .btn-next,
.order_list[data-v-630069ba] .paging .btn-prev {
  background-color: white;
  font-size: 0.08333rem;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.20833rem;
  font-weight: normal;
}
.order_list[data-v-630069ba] .el-dialog__wrapper .el-dialog {
  width: 2.60417rem !important;
  border-radius: 0.05208rem;
}
.order_list[data-v-630069ba] .el-dialog__wrapper .el-dialog .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.order_list[data-v-630069ba] .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 0.15625rem;
}
.order_list[data-v-630069ba] .el-dialog__wrapper .code {
  width: 2.60417rem;
  height: 2.60417rem;
}
.order_list[data-v-630069ba] .el-dialog__wrapper .tips {
  text-align: center;
}
