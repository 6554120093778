.zh-toast {
  position: fixed;
  bottom: 0.52083rem;
  left: 50%;
  box-sizing: border-box;
  max-width: 80%;
  height: 0.20833rem;
  line-height: 0.10417rem;
  padding: 0.05208rem 0.10417rem;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  text-align: center;
  z-index: 9999;
  font-size: 0.07292rem;
  color: #fff;
  border-radius: 0.02604rem;
  background: rgba(0, 0, 0, 0.7);
  animation: show-toast .5s;
  -webkit-animation: show-toast .5s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.zh-toast.zh-word-wrap {
  width: 80%;
  white-space: inherit;
  height: auto;
}
.zh-toast.zh-toast-top {
  top: 0.26042rem;
  bottom: inherit;
}
.zh-toast.zh-toast-center {
  top: 50%;
  margin-top: -0.10417rem;
  bottom: inherit;
}
@keyframes show-toast {
  from {
      opacity: 0;
      transform: translate(-50%, -0.05208rem);
      -webkit-transform: translate(-50%, -0.05208rem);
  }
  to {
      opacity: 1;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
  }
}