.login[data-v-412ed304] {
  width: 100vw;
  height: 94vh;
  background-color: #363E4B;
  padding: 0px 0.57292rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login .loginTitle[data-v-412ed304] {
  width: 100%;
  font-size: 0.25rem;
  font-weight: 800;
  color: #999999;
  margin-bottom: 1.71875rem;
}
.login .loginTitle span[data-v-412ed304] {
  font-size: 0.52083rem;
  font-weight: 800;
  color: #FFFFFF;
}
.login .loginInp[data-v-412ed304] {
  width: 100%;
}
.login .loginInp .inpbox[data-v-412ed304] {
  position: relative;
  margin-bottom: 0.57292rem;
}
.login .loginInp .inpbox p[data-v-412ed304] {
  font-size: 0.3125rem;
  color: #999999;
  margin-bottom: 0.17708rem;
}
.login .loginInp .inpbox input[data-v-412ed304] {
  width: 100%;
  height: 1.04167rem;
  background: #1D2533;
  border-radius: 0.16667rem;
  border: none;
  font-size: 0.25rem;
  color: #fff;
  text-indent: 0.35417rem;
}
.login .loginInp .inpbox input[data-v-412ed304]:focus {
  outline: 1px solid #F88503;
}
.login .loginInp .inpbox input[data-v-412ed304]::placeholder {
  font-size: 0.25rem;
  color: #666666;
}
.login .loginInp .inpbox .codenumBox[data-v-412ed304] {
  position: absolute;
  right: 0px;
  bottom: 0.3125rem;
  width: 2.08333rem;
  height: 0.41667rem;
  border-left: 1px solid #666;
  font-size: 0.25rem;
  text-align: center;
  line-height: 0.41667rem;
  color: #F88503;
}
.login .loginInp button[data-v-412ed304] {
  width: 100%;
  height: 0.91667rem;
  background: #F88503;
  border-radius: 0.45833rem;
  color: #fff;
  font-size: 0.3125rem;
  font-weight: 800;
  letter-spacing: 0.15625rem;
  border: none;
}
