.goods-container[data-v-398df57a] {
  background: linear-gradient(180deg, #333c58 0%, #10161b 100%);
  width: 100%;
  padding: 0.20833rem 0.52083rem;
}
.goods-container .backBtn[data-v-398df57a] {
  display: flex;
  align-items: center;
}
.goods-container .back[data-v-398df57a] {
  width: 0.78125rem;
  display: flex;
  align-items: center;
  padding: 0.05208rem 0;
  text-align: center;
  font-size: 0.11458rem;
  color: #fff;
  margin-bottom: 0.10417rem;
  margin-right: 0.15625rem;
  cursor: pointer;
}
.goods-container .back .el-icon-back[data-v-398df57a] {
  font-size: 0.16667rem;
  color: #fff;
  line-height: 1;
  margin-right: 0.02604rem;
}
.goods-container .backHome[data-v-398df57a] {
  width: 0.67708rem;
}
.goods-container[data-v-398df57a] .el-dialog__wrapper .el-dialog {
  width: 2.60417rem !important;
  border-radius: 0.05208rem;
}
.goods-container[data-v-398df57a] .el-dialog__wrapper .el-dialog .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.goods-container[data-v-398df57a] .el-dialog__wrapper .el-dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 0.15625rem;
}
.goods-container[data-v-398df57a] .el-dialog__wrapper .code {
  width: 2.60417rem;
  height: 2.60417rem;
}
.goods-container[data-v-398df57a] .el-dialog__wrapper .tips {
  text-align: center;
}
.goods-container .goods[data-v-398df57a] {
  display: flex;
}
.goods-container .goods .img[data-v-398df57a] {
  width: 3.64583rem;
  height: 2.39583rem;
  margin-right: 0.3125rem;
}
.goods-container .goods .info[data-v-398df57a] {
  flex: 1;
}
.goods-container .goods .info .name[data-v-398df57a] {
  margin-bottom: 0.10417rem;
  font-size: 0.14583rem;
  font-weight: 700;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.goods-container .goods .info .block-num[data-v-398df57a] {
  display: flex;
  justify-content: space-between;
  margin: 0.14583rem 0 0.07292rem;
  font-size: 0.09375rem;
}
.goods-container .goods .info .block-num .leave[data-v-398df57a] {
  display: flex;
  align-items: center;
  height: 0.20833rem;
  background: #0d1323;
  border-radius: 0.03125rem;
  color: #fff;
  line-height: 0.20833rem;
  overflow: hidden;
}
.goods-container .goods .info .block-num .leave .txt[data-v-398df57a] {
  width: 0.36458rem;
  height: 0.20833rem;
  text-align: center;
  background: linear-gradient(to right, #efbe40, #c49623);
}
.goods-container .goods .info .block-num .leave .num[data-v-398df57a] {
  width: 0.52083rem;
  text-align: center;
}
.goods-container .goods .info .block-num .total[data-v-398df57a] {
  color: #999;
}
.goods-container .goods .info .counterbox[data-v-398df57a] {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 0.06771rem;
}
.goods-container .goods .info .counterbox .counterbox_name[data-v-398df57a] {
  font-size: 0.08333rem;
  font-weight: 800;
  color: #FFFFFF;
}
.goods-container .goods .info .counterbox .counterbox_num[data-v-398df57a] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.94271rem;
  height: 0.26042rem;
  border-radius: 0.04688rem;
  border: 1px solid #3B4871;
  font-size: 0.10417rem;
  font-weight: 400;
  color: #FFFFFF;
}
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a] {
  flex: 1;
  height: 100%;
  line-height: 0.26042rem;
  text-align: center;
  border-right: 1px solid #3B4871;
}
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a]:last-child {
  border-right: 0px;
}
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a]:nth-child(1),
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a]:nth-child(3) {
  font-size: 0.1875rem;
  color: #D8A931;
  cursor: pointer;
  user-select: none;
}
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a]:nth-child(1):active,
.goods-container .goods .info .counterbox .counterbox_num > div[data-v-398df57a]:nth-child(3):active {
  font-size: 0.23958rem;
}
.goods-container .goods .info .date[data-v-398df57a] {
  display: flex;
  align-items: center;
  color: #ccc;
  font-size: 0.09375rem;
  line-height: 0.3125rem;
}
.goods-container .goods .info .date .txt[data-v-398df57a] {
  margin-right: 0.10417rem;
}
.goods-container .goods .info .code[data-v-398df57a] {
  margin-bottom: 0.09375rem;
  color: #ccc;
  font-size: 0.09375rem;
  line-height: 0.26042rem;
}
.goods-container .goods .info .happy-hour[data-v-398df57a] {
  border-top: 1px solid #444444;
  display: flex;
}
.goods-container .goods .info .happy-hour .box[data-v-398df57a] {
  padding: 0.15625rem 0 0.15625rem 0.15625rem;
}
.goods-container .goods .info .happy-hour .box .title[data-v-398df57a] {
  margin-bottom: 0.15625rem;
  color: #fff;
  font-size: 0.09375rem;
}
.goods-container .goods .info .happy-hour .box .price-footer[data-v-398df57a] {
  display: flex;
  align-items: flex-end;
}
.goods-container .goods .info .happy-hour .box .price-footer .price-new[data-v-398df57a] {
  margin-right: 0.09375rem;
  font-size: 0.1875rem;
  line-height: 1;
  color: #ff0000;
  font-weight: 700;
}
.goods-container .goods .info .happy-hour .box .price-footer .price-new i[data-v-398df57a] {
  font-size: 0.09375rem;
  font-style: initial;
}
.goods-container .goods .info .happy-hour .box .price-footer .price-old[data-v-398df57a] {
  display: flex;
  align-items: flex-end;
}
.goods-container .goods .info .happy-hour .box .price-footer .price-old .txt[data-v-398df57a] {
  color: #959595;
  font-size: 0.07292rem;
  text-decoration: line-through;
}
.goods-container .goods .info .happy-hour .box .price-footer .discount[data-v-398df57a] {
  height: 0.15625rem;
  margin-right: 0.05208rem;
  line-height: 0.15625rem;
  border-radius: 0.03125rem;
  text-align: center;
  padding: 0 0.05208rem;
  font-size: 0.09375rem;
  color: #fff;
  font-weight: 700;
  background: linear-gradient(to right, #efbe40, #c49623);
}
.goods-container .goods .info .happy-hour .box .time[data-v-398df57a] {
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  font-size: 0.14583rem;
  color: #fff;
  font-weight: 700;
}
.goods-container .goods .info .block-buy[data-v-398df57a] {
  display: flex;
  align-items: center;
}
.goods-container .goods .info .block-buy .btn[data-v-398df57a] {
  width: 0.625rem;
  height: 0.3125rem;
  margin-right: 0.3125rem;
  line-height: 0.3125rem;
  text-align: center;
  background: #d5a223;
  border-radius: 0.03125rem;
  color: #fff;
  font-size: 0.13542rem;
  font-weight: 700;
  cursor: pointer;
}
.goods-container .goods .info .block-buy .btn.disable[data-v-398df57a] {
  background: #888;
  color: #bbb;
  cursor: no-drop;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] {
  display: flex;
  font-size: 0.09375rem;
  color: #fff;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree {
  width: 0.13542rem;
  height: 0.13542rem;
  margin-right: 0.05208rem;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree .el-checkbox .el-checkbox__inner {
  width: 0.13542rem;
  height: 0.13542rem;
  background: #191919;
  border: none;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree .el-checkbox .el-checkbox__inner::after {
  width: 0.02604rem;
  height: 0.05208rem;
  left: 0.05208rem;
  top: 0.03125rem;
  border-right: 2px solid #ff0000;
  border-bottom: 2px solid #ff0000;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.15s ease-in;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree .el-checkbox.is-checked .el-checkbox__inner::after {
  transform: rotate(45deg);
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree input[type="checkbox"] {
  position: relative;
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  width: 0.13542rem;
  height: 0.13542rem;
  outline: none;
  background-color: #191919;
  margin: 0;
  cursor: pointer;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree input[type="checkbox"]:checked::after {
  border-right: 2px solid #ff0000;
  border-bottom: 2px solid #ff0000;
  transform: rotate(45deg);
  display: block;
}
.goods-container .goods .info .block-buy .agree[data-v-398df57a] .checkbox-agree input[type="checkbox"]::after {
  display: inline-block;
  content: "";
  width: 0.02604rem;
  height: 0.05208rem;
  position: absolute;
  left: 0.05208rem;
  top: 0.03125rem;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.15s ease-in;
}
.goods-container .goods .info .block-buy .agree .link[data-v-398df57a] {
  color: #ff0000;
  text-decoration: none;
}
.goods-container .content[data-v-398df57a] {
  display: flex;
  padding-top: 0.23438rem;
}
.goods-container .content .details[data-v-398df57a] {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.goods-container .content .details .title[data-v-398df57a] {
  margin-bottom: 0.13021rem;
  font-size: 0.125rem;
  font-weight: 700;
  color: #fff;
}
.goods-container .content .details .txt[data-v-398df57a] {
  font-size: 0.08333rem;
  color: #ccc !important;
  line-height: 0.16667rem;
}
.goods-container .content .details .txt img[data-v-398df57a] {
  width: 100%;
  margin: 0.15625rem 0;
}
.goods-container .content .recommend[data-v-398df57a] {
  display: flex;
  flex-direction: column;
  margin-left: 0.3125rem;
}
.goods-container .content .recommend .title[data-v-398df57a] {
  margin-bottom: 0.13021rem;
  font-size: 0.125rem;
  font-weight: 700;
  color: #fff;
}
.goods-container .content .recommend .list[data-v-398df57a] {
  display: flex;
  flex-direction: column;
}
.goods-container .content .recommend .list .item[data-v-398df57a] {
  display: flex;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
}
.goods-container .content .recommend .list .item .img[data-v-398df57a] {
  width: 2.03125rem;
  height: 1.35417rem;
  margin-bottom: 0.05208rem;
  cursor: pointer;
}
.goods-container .content .recommend .list .item .info[data-v-398df57a] {
  padding: 0.05208rem 0 0.15625rem;
}
.goods-container .content .recommend .list .item .info .title[data-v-398df57a] {
  margin-bottom: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: 700;
}
.goods-container .content .recommend .list .item .goods-footer[data-v-398df57a] {
  display: flex;
  align-items: center;
  width: 100%;
}
.goods-container .content .recommend .list .item .goods-footer .price-new[data-v-398df57a] {
  margin-right: 0.05208rem;
  font-size: 0.10417rem;
  color: #ff0000;
  font-weight: 700;
}
.goods-container .content .recommend .list .item .goods-footer .price-new i[data-v-398df57a] {
  font-size: 0.0625rem;
  font-style: initial;
}
.goods-container .content .recommend .list .item .goods-footer .price-old[data-v-398df57a] {
  color: #959595;
  font-size: 0.07292rem;
  text-decoration: line-through;
}
.goods-container .content .recommend .list .item .goods-footer .name[data-v-398df57a] {
  font-size: 0.07292rem;
  color: #d5a223;
  margin-left: auto;
}
