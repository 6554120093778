.addresinp_view[data-v-2c840257] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.20833rem;
  min-height: 2.86979rem;
  background: #2B2B2B;
}
.addresinp_view .addresinp_view_title[data-v-2c840257] {
  position: relative;
  height: 0.3125rem;
  background: #262626;
  line-height: 0.3125rem;
  padding: 0px 0.19792rem;
  font-size: 0.10417rem;
  font-weight: 800;
  color: #FFFFFF;
}
.addresinp_view .addresinp_view_title[data-v-2c840257]::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 0.14583rem;
  background-color: #D5A223;
}
.addresinp_view .addresinp_view_form[data-v-2c840257] {
  padding: 0.25521rem 0.20313rem 0px;
}
.addresinp_view .addresinp_view_form .addresinp_view_row[data-v-2c840257] {
  display: flex;
  align-items: center;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0.20833rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox[data-v-2c840257] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox > div[data-v-2c840257] {
  margin-right: 0.09375rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox > div[data-v-2c840257]:last-child {
  margin-right: 0px;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox .xxdz[data-v-2c840257] {
  width: 0.625rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257] {
  width: 4.14063rem;
  height: 1rem;
  background: #3A3A3A;
  border-radius: 0.04167rem;
  border: 1px solid #666666;
  padding: 0.10417rem;
  resize: none;
  font-size: 0.07292rem;
  color: #fff;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257]::placeholder {
  font-size: 0.08333rem;
  color: #666666;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257]::-webkit-scrollbar {
  width: 0.03125rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257]::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 2px;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257]::-webkit-scrollbar-thumb {
  background: #2B2B2B;
  border-radius: 0.05208rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inputbox textarea[data-v-2c840257]::-webkit-scrollbar-thumb:hover {
  background: #2B2B2B;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .inpwidth[data-v-2c840257] {
  width: 2.34375rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .addresinfo[data-v-2c840257] {
  width: 3.125rem;
  min-height: 1.11458rem;
  background: #313131;
  border-radius: 0.07292rem;
  padding: 0.14583rem 0.23438rem 0.19792rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .addresinfo .addresinfo_row[data-v-2c840257] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.15625rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .addresinfo .addresinfo_row > div[data-v-2c840257] {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .addresinfo .addresinfo_row > div p[data-v-2c840257] {
  margin: 0.02604rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .addresinfo .addresinfo_row > div p span[data-v-2c840257] {
  font-size: 0.08333rem;
  color: #999999;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .oddNumber div[data-v-2c840257] {
  font-size: 0.09375rem;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0.07813rem;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .oddNumber input[data-v-2c840257] {
  width: 3.125rem;
  height: 0.3125rem;
  background: #3A3A3A;
  border-radius: 0.04167rem;
  border: 1px solid #666666;
  font-size: 0.08333rem;
  font-weight: 500;
  text-indent: 0.15625rem;
  color: #ffffff;
}
.addresinp_view .addresinp_view_form .addresinp_view_row .oddNumber input[data-v-2c840257]::placeholder {
  font-size: 0.07292rem;
  font-weight: 500;
  text-indent: 0.15625rem;
  color: #666666;
}
.addresinp_view .addresinp_view_form .addresinp_view_row button[data-v-2c840257] {
  width: 0.9375rem;
  height: 0.3125rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.10417rem;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}
.addresinp_view .addresinp_view_form .addresinp_view_row button[data-v-2c840257]:hover {
  background: #f3af04;
}
.addresinp_view .addresinp_view_form .center[data-v-2c840257] {
  justify-content: center;
}
.addresinp_view .addresinp_view_form .gap .inputbox[data-v-2c840257] {
  margin-right: 0.26042rem;
}
.addresinp_view .addresinp_view_form .gap .inputbox[data-v-2c840257]:last-child {
  margin-right: 0px;
}
