button[data-v-a44769fa] {
  cursor: pointer;
}
.addressList[data-v-a44769fa] {
  height: 4.46354rem;
  background: #2B2B2B;
}
.addressList .addressList_item[data-v-a44769fa] {
  display: flex;
  align-items: center;
  height: 0.76042rem;
  padding: 0px 0.21875rem;
  border-bottom: 1px solid #cccccc3f;
  font-size: 0.09375rem;
  font-weight: 500;
  color: #FFFFFF;
}
.addressList .addressList_item > div[data-v-a44769fa] {
  flex: 1;
  display: flex;
  align-items: center;
}
.addressList .addressList_item > div i[data-v-a44769fa] {
  width: 0.10417rem;
  height: 0.10417rem;
  background: #5A5A5A;
  border-radius: 50%;
  margin-right: 0.04688rem;
  cursor: pointer;
}
.addressList .addressList_item > div .activeicon[data-v-a44769fa] {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAPCAYAAAAPr1RWAAAAAXNSR0IArs4c6QAAAKRJREFUOE+t09ERhCAMBNDdCmzhSrIELcUK7jpQS7GyODDkBhEkOPDhF/M2JIbofERkAvAFsLCnHeA1mP3wBN5JTl0qz8GueorICGAgub9pUQlWXAK6kZxbAp5gxd10dQjmgBrscfdJLlYDLPAfbwmwwhfcEtAC3/CngFY4i+cCABzR0P2CWP6q4hIllaplhouVq/SmFfGLqusfNvhD8mdpRXznBL0CbPq766TXAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 0.05729rem 0.03646rem;
  background-color: #E83D3A;
}
.addressList .addressList_item > div button[data-v-a44769fa] {
  width: 0.72917rem;
  height: 0.26042rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-right: 0.20833rem;
}
.addressList .addressList_item > div .deletebtn[data-v-a44769fa] {
  background-color: #444444;
  margin-right: 0px;
}
.addressList .addressList_item > div[data-v-a44769fa]:first-child {
  flex: 2;
  align-items: start;
  flex-direction: column;
}
.addressList .addressList_item > div:first-child p[data-v-a44769fa] {
  margin: 0.02604rem 0px;
}
.addressList .addressList_item > div:first-child p span[data-v-a44769fa] {
  font-size: 0.08333rem;
  color: #999999;
  margin-right: 0.07813rem;
}
.addressList .addressList_btn[data-v-a44769fa] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.67708rem;
}
.addressList .addressList_btn button[data-v-a44769fa] {
  width: 0.78125rem;
  height: 0.26042rem;
  background: #D5A223;
  border-radius: 0.03125rem;
  font-size: 0.09375rem;
  font-weight: bold;
  color: #FFFFFF;
}
.addressinfo[data-v-a44769fa] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
