.item_head[data-v-7780ba40] {
  position: relative;
  height: 0.52083rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
}
.item_head i[data-v-7780ba40] {
  position: absolute;
  top: 50%;
  right: 0.05208rem;
  transform: translateY(-50%);
  width: 0.13021rem;
  height: 0.06771rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAASCAYAAACjMAXnAAAAAXNSR0IArs4c6QAAAcRJREFUSEu1VcF1wjAMlXxIjmWDskFhgiYTtJ2g5ILiG0zQbtD2ZMwldAM2KEwA3YAR4AjvYfeJR3huiCFNQbc4kf6X8r+Mw+GwZYx5A4BFGIb9JEmWcKXQWo8A4NlaO0rTNEGtNYPd7PHmQRDE1yCQA+d9GWPiIji/GxPR0yWb11q/AsCLWxMR2wzeAwAe+yHysVyCgFKqI4TICrU+iaiDfFgcCZ9Zaz/SNGVitYP1ZK39AoCGU2RKRBE/78B9BIwxiZSSRfLnUEo1hRAzF9ha+x2GYZRr6gC+JzABgHsXqQ6BLMsam82GO245tVbGmJaUcpGf/QLnpPV6PUHEuwKBWErJxCrFYDAYI+KDC4yIUbfbnbsFfoHzCw+BJSLGxeQyJmX68U3vCJwL7oXCneb+5+OzBDzK7hPRexnRUvATBLxLSCkVCSH4P7uxs5TvX3nBHQKsWDeOCJyzVC1wTvKMck5EbUcjM0Rs5iBFS9UG9xGw1rIrWBePRUsFQdCscj+cHLvLuGw/l3S0KrPUvzrPk8ts5HoZADpENK60DNz1WjVBa81jZuvcOjlTROxV2QMnl0xVEqzw7XbLF8bCXZlV8/m7Hxp37BZSKXxqAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
}
.item_head .unfoldicon[data-v-7780ba40] {
  transform-origin: center center;
  transform: rotate(180deg);
}
.item_cntent[data-v-7780ba40] {
  display: none;
  background: linear-gradient(133deg, #6B6B8B 0%, #2D2D35 100%);
}
.unfold[data-v-7780ba40] {
  display: block;
}
.activeitem[data-v-7780ba40] {
  background: rgba(220, 173, 44, 0.84);
}
