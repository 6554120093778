.select_inp[data-v-5c32942a] {
  position: relative;
  flex: 1;
  height: 0.3125rem;
  background: #3A3A3A;
  border-radius: 0.04167rem;
  border: 1px solid #666666;
  text-indent: 0.10417rem;
  line-height: 0.3125rem;
  cursor: pointer;
}
.select_inp .placeholdertext[data-v-5c32942a] {
  color: #666666;
  font-size: 0.08333rem;
}
.select_inp .dataValue[data-v-5c32942a] {
  color: #ffffff;
  font-size: 0.08333rem;
}
.select_inp i[data-v-5c32942a] {
  position: absolute;
  top: 45%;
  right: 0.10417rem;
  transform-origin: center center;
  width: 0.07813rem;
  height: 0.04167rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAASCAYAAACjMAXnAAAAAXNSR0IArs4c6QAAAcRJREFUSEu1VcF1wjAMlXxIjmWDskFhgiYTtJ2g5ILiG0zQbtD2ZMwldAM2KEwA3YAR4AjvYfeJR3huiCFNQbc4kf6X8r+Mw+GwZYx5A4BFGIb9JEmWcKXQWo8A4NlaO0rTNEGtNYPd7PHmQRDE1yCQA+d9GWPiIji/GxPR0yWb11q/AsCLWxMR2wzeAwAe+yHysVyCgFKqI4TICrU+iaiDfFgcCZ9Zaz/SNGVitYP1ZK39AoCGU2RKRBE/78B9BIwxiZSSRfLnUEo1hRAzF9ha+x2GYZRr6gC+JzABgHsXqQ6BLMsam82GO245tVbGmJaUcpGf/QLnpPV6PUHEuwKBWErJxCrFYDAYI+KDC4yIUbfbnbsFfoHzCw+BJSLGxeQyJmX68U3vCJwL7oXCneb+5+OzBDzK7hPRexnRUvATBLxLSCkVCSH4P7uxs5TvX3nBHQKsWDeOCJyzVC1wTvKMck5EbUcjM0Rs5iBFS9UG9xGw1rIrWBePRUsFQdCscj+cHLvLuGw/l3S0KrPUvzrPk8ts5HoZADpENK60DNz1WjVBa81jZuvcOjlTROxV2QMnl0xVEqzw7XbLF8bCXZlV8/m7Hxp37BZSKXxqAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
}
.select_inp .select_list[data-v-5c32942a] {
  display: none;
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  height: 1.17188rem;
  border: 1px solid #666666;
  background-color: #3A3A3A;
  overflow-y: auto;
  z-index: 99;
}
.select_inp .select_list ul[data-v-5c32942a] {
  padding: 0px;
  margin: 0px;
}
.select_inp .select_list ul li[data-v-5c32942a] {
  height: 0.23438rem;
  line-height: 0.23438rem;
}
.select_inp .select_list ul li[data-v-5c32942a]:hover {
  background: #2B2B2B;
}
.select_inp .select_list[data-v-5c32942a]::-webkit-scrollbar {
  width: 0.03125rem;
}
.select_inp .select_list[data-v-5c32942a]::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 2px;
}
.select_inp .select_list[data-v-5c32942a]::-webkit-scrollbar-thumb {
  background: #2B2B2B;
  border-radius: 0.05208rem;
}
.select_inp .select_list[data-v-5c32942a]::-webkit-scrollbar-thumb:hover {
  background: #2B2B2B;
}
.select_inp:hover i[data-v-5c32942a] {
  transform: rotate(180deg);
}
.select_inp:hover .select_list[data-v-5c32942a] {
  display: block;
}
