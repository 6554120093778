.navdarlist[data-v-904c3d02] {
  width: 1.5625rem;
  height: 3.125rem;
  overflow: auto;
  border-radius: 0.05208rem;
}
.navdarlist .item[data-v-904c3d02] {
  background: linear-gradient(133deg, #6B6B8B 0%, #2D2D35 100%);
  border-radius: 0.05208rem;
  border: 1px solid rgba(255, 255, 255, 0.19);
  margin-bottom: 0.08854rem;
  text-align: center;
  line-height: 0.52083rem;
  font-size: 0.17708rem;
  font-weight: 800;
  color: #FFFFFF;
}
.navdarlist[data-v-904c3d02]::-webkit-scrollbar {
  width: 0.03125rem;
}
.navdarlist[data-v-904c3d02]::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 2px;
}
.navdarlist[data-v-904c3d02]::-webkit-scrollbar-thumb {
  background: #2B2B2B;
  border-radius: 0.05208rem;
}
.navdarlist[data-v-904c3d02]::-webkit-scrollbar-thumb:hover {
  background: #2B2B2B;
}
.Additionalitem[data-v-904c3d02] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1.5625rem;
  height: 0.52083rem;
  margin-top: 0.15625rem;
  padding: 0px 0.13021rem;
  font-size: 0.14583rem;
  font-weight: 800;
  color: #D5A223;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAADICAMAAAADQW+EAAAAXVBMVEUICywLDi0GCSwKDCwIDCwABCsECCsHCisLDSwGCSsICiwCBSsCBisBBSsECCwNDywKDCwMDiwCBisNDi0DBiwDCCsNEC0AAAAGBysFCCsLDSwJCywJDCwNECwABCtZ7Hm2AAAAHnRSTlPbzeTT2Pzs4dDn3vfy+enG1sr0yPDuxAAZkXCrVji56/ksAAADTklEQVR42uzY0UpbQRSG0Y2iVBKltok5J5F5/8dsttKSQG9a9yBb1voZ5jYXHxNNHK4sr+vpdjfgH2xvT+vrcrgSl1Wt3wf8p2/r8tewlnUz4AM2F2mFrKhN6zqsdUCB9Sqs5XlAieflIixdUebnIYXvQYqtv8M6Dih0fA9r8f8gpTbLOSxfhJRbM6zlbkD1kxWH04Bi6zmsGFAsDrEMKLeE3xqY4Bj+xGKCU7wMKPcSNwPKRTwOKLf1YwNTCIskLHoQFklY9BB3MIGwSMKiB2GRhEUPwiIJix6ERRIWPcQjTCAskrDoQVgkYdGDsJgiHjdm9Yvz2ThO9fFi2aQXC+oJizfCogdhkYRFD8JiivgBEwiLJCx6EBZJWPQgLKaILUwgLJKw6EFYJGHRg7BIM8LabR2n+sR2Z1a/+PRPYF9ysYMJhEUSFj3EfmdWv/j0T2BfcnE+e8epPrGHcsLinbDoIe5hAmGRhEUPwiIJix6ERRIWPcT9k1n9vFgkL5b1WDxBPWHxRlj0ICySsOhBWEwRDzDBOaynh+R2V95eLKoJiz+ERQ/CIgmLHjKsm5zbXXl7sZgibmACYZGERQ/C+tVeHaw2DkNhGFVpYMpsHGOQbCt5/9ecXAIzBGbTVlpces6P0MLbD4sgLHIQFkFY5CAsgrDIofyCCYRFEBY5CIsgLHIQFkFY5CAsgrDIofyGCYRFEBY5CIsgLHIQFlMUsxnzxyL4Y1mOAQAAAAD8XBeYQFgEYZGDsAjCIgdhEYRFDo+wrhfHGX3K5Wo2fp5CpihXmEBYBGGRg7AIU8J6d5zRxx+LKco7TCAsgrDIQVgEYZGDsAjCIgdhEYRFDuUDJhAWQVjkICyCsMhBWARhkUP5eDMbP38spihvMIGwCMIiB2ERhEUOwiIIixyERRAWOZQVJhAWQVjkUOoKw9XSVxiul2OF4Y5ybDDcUW7b+ti2rW73uPtW9g2G20vrGwzWW2nnBoOdrbS9bjBU3Vtp7VxgqLNFWK0uMFBtz7DuCwx0f4blMWT0QxhhhWOBQY72L6y9LzBEb3/DCmeFAc72Gla71cXsm6u39hJW2M/6+BLc7q/d595ew3ravYd8Q2T1v7DC/Tx6r/ApvR/nvb34Azz0R9nbavHIAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
  border-radius: 0.05208rem;
  border: 1px solid rgba(255, 255, 255, 0.19);
}
.Additionalitem .icon[data-v-904c3d02] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Additionalitem .icon i[data-v-904c3d02] {
  width: 0.11979rem;
  height: 0.13021rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAA0CAYAAAAaNmH0AAAAAXNSR0IArs4c6QAAAztJREFUaEPtmkFS20AQRfuPsoJFyAki7BXgqnCDmBPgrFPYcAM4AeQEkBMYRGXvG0BOgFJlwwojbgCLZMWoUyNbRFZwaWY0EuVUvJ3untdf7dZM2yBHn+E3fxOx2GZGG8wrBGxah2b+stEbHxX5o8igaH0YNHfBfEiAX2SrvV41fKL0k+iXUnheNlXCX583O3HMfQAr2mqaGFYFn5QJUd+Exdi2CvikVKR3ZQxj6uAa/q7vr/wU4q6yUskm6Bp+dNY4IuDQVEQre5fwN33fl0Jc1aK6ytYl/HXQ2GfCsZWKNk4u4YdB8xJEH204rHxcwo+CJltB2Dq5gq+tPVbRbW7O/XbM3oWtiFZ+rpT/D28oPxPttbq3p0VuhUfi16h5Abm1thNdloZXAeruNkLK1bW9KHIFPyCi7aJgLtaZ+EerO9a6hRWWjQKq5Rg8zZyZv7Z6430dIbTgpyfKCMBbnaBlbHRLRu2hBa8MaznfaPb3VBxt+En5NEIQPpRRdp4vMz8ux7G/uhc96MY3gp8ejUPX5aPA6U3cbn2OQl1wo7JJg6q+T0/i0mUCui+lfGJGymcTgBQDIrw3USpvqxT3RNzReSG9tI8VvAo06UDeCUA9mwSY6Dt5ct+0VLJ7WcPPPgVPjea0XmIK2oM8slXbKXwaLHkSntcBs89Ae+ZpMIcEhMtSDky6SdETLa180QZVrv/b8Kq3k0eluoqx+pLurU+VCjj2xCExtZ2Org2yYOYHgAYC8dm8L/dfZTMMGscgaJ3qDFhKmvLpkowP8l/2Z3jVLX4JcVHJvL0keuLOHC7F8VY2gWf4YdC8ANFsi3OxqcsYuQQS+FqHqCWTyV5WUOvouiR46p5eWFDnFc8RO6XqYxQ0a7tcu4In5mijN17F6Kxx91q9vEwyG91bKOXrnQCXIc74Lkn5bmHh1VRtYeGnyjeistc5R5VgFCat+YXrNulIcCH7PIgP1rvjk8nxIFic0skOpxL415jBGxV4xhigT+s7t6rU/8wq1TGBmE9cDpNsAef55YdTM5eRZBomPVVL9f3mqpUh3wvEu/kb1YsX8Mn/aahDIB/Eft2tNJldAiExRULQIC2TfJ6/AfvlnnofhhweAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100% 100%;
  margin-right: 0.05208rem;
}
.Additionalitem .go_icon[data-v-904c3d02] {
  width: 0.04688rem;
  height: 0.07813rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAgCAYAAADwvkPPAAAAAXNSR0IArs4c6QAAAwlJREFUSEud1U1IFGEYB/D/f1Y3rYguIVGmM1uo69ClCDpFIGt1ij6Eot3JKIggLxF0qqBDBEEXoa9DzhpRYdDBKIMKuhRBh3JXDdrZBS3o49AXarAzT8zAbs6suqPv8d3n+e379TzDUbNpq83IVYJtQjmoJ60HWORgNq19Bri6lC8iZ3TDurQYz8UsgGog+Ua8LneCXbAXgnLE1DoEHASxZGaiAEOo+7VP7/r2JyxIN3C0L7bJVuQRwYZA4kiN/E20GBOfwoAe5o4P5to1RS55CiDuX6F8qRFJtBr599XAMuYGZu6vWo7pFQMEOn2gyCTo7NFThaH5QB/mBso5KNlY7BqBY75EEUcox/VU/uZcYAVWCsyasZNCuUIwEtj2lfakdYqEBNE5MW/b6eZOIjIAYHlg24PLHGe/2l2Ynjk/L+aBt9fFYdc+JbHGD+Jtbe3UjpaDn7+X5qtibuDwHbWBRQ4S3BwAx2sdO9HSXRhz50NhbuD4/bX1P6ejtwnuCZzV92iR8Q1HPn4LjZUvJq1dBHjGf9O43G7kTi8Yc5ERUz0qVMpPRCAf9JTVuijMuxhTe0dyo/c2gWJ7MhddFDZmqhuL5GuC9R4mMqkb1rIFYxlT3QJwiOTK8rmJPGs3rI4FYaN9asJW+LC0ohImtHfqycKT0FimTzsABf0V5SU4qxu5C6HfWSatHiN4HeCMPxd39OhGvjd0BWRN7TzIc/5iR1EBkvFU7m6o2hQBs/2x68FWJJCpiCO72w7n3UbqG7OembxATXZcu1dROoLfAqdDN/JvZutpFZhXg1PRhyQTgT72NaKgo+2QNTwbVHEB+VvNKycVZQjkFn/tSUFxnO1t3YXCXJAPG+lvWu04keckW/1tRsbqyG3rU7mv80FlbPRWc7OtRF6SaAys6M1Sx+lUuws/qkEeNtzXGCOjr0isCrSVxyvq/+5t7JqYCgN5WCatDRDc60+QO/FGy+B2FMNCHpZNa48A7vpftOiNp3I9s319qsEcSzepNiK9Ai6FOA9mlke15ODv/wCJ7iIvE4M1SgAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100% 100%;
}
